// Blog.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Article from '../components/Article';
import Pagination from '../components/Pagination';
import { Link, Redirect } from 'react-router-dom';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [count, setCount] = useState(0);
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    const [active, setActive] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/?page=1`);
                setPosts(res.data.results);
                setCount(res.data.count);
                setPrevious(res.data.previous);
                setNext(res.data.next);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayPosts = () => {
        let display = [];
        let result = [];

        posts.map(post => {
            return display.push(
                <Article
                    title={post.title}
                    title_tag={post.title_tag}
                    slug={post.slug}
                    img={post.img}
                    short_description={post.short_description}
                    comments={post.comments}
                    post_date={post.post_date}
                    category={post.category}
                    featured={post.featured}
                />
            );
        });

        for (let i = 0; i < posts.length; i += 3) {
            result.push(
                <div key={i} className="row">
                        {display[i]}
                        {display[i+1] ? display[i+1] : null}
                        {display[i+2] ? display[i+2] : null}
                </div>
            );
        }

        return result;
    };

    const visitPage = (page) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/blog/?page=${page}`)
        .then(res => {
            setPosts(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            setActive(page);
        })
        .catch(err => {

        });
    };

    const previous_number = () => {
        axios.get(previous)
        .then(res => {
            setPosts(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            if (previous)
                setActive(active-1);
        })
        .catch(err => {

        });
    };

    const next_number = () => {
        axios.get(next)
        .then(res => {
            setPosts(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            if (next)
                setActive(active+1);
        })
        .catch(err => {

        });
    };


    return (
        <div>
            <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 text-center text-md-left">
                            <h2 className="f-700">Recent Blogs</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-right">
                            <ul className="pagination-inner">
                                <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- work gallery start --> */}
        <section className="blog-listing our-articles pt-80 pb-80">
            <div className="container">
                {displayPosts()}
                <div className="row">
                        <div className="col-12 text-center">
                            <div className="pagination-sml no-border pt-10 text-center">
                                <Pagination
                                    itemsPerPage={3}
                                    count={count}
                                    visitPage={visitPage}
                                    previous={previous_number}
                                    next={next_number}
                                    active={active}
                                    setActive={setActive}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        {/* <!-- work gallery end --> */}
    </div>
    );
};

export default Blog;

