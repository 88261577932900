import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, AuthReducer, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (AuthReducer.isLoading) {
                return 
                <div className="blobs flex-center">
                  <div className="blob-center"></div>
                  <div className="blob"></div>
                  <div className="blob"></div>
                  <div className="blob"></div>
                  <div className="blob"></div>
                  <div className="blob"></div>
                  <div className="blob"></div>
              </div>
              ;
            } else if (!AuthReducer.isAuthenticated) {
                return <Redirect to="/login" />;
            } else {
                return <Component {...props} />;
            }
        }}
    />
);

const mapStateToProps = (state) => ({
    AuthReducer: state.AuthReducer,
});

export default connect(mapStateToProps)(PrivateRoute);
