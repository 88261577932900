// Services.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServiceCard from '../components/ServiceCard';
import { Link, Redirect } from 'react-router-dom';
const Services = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/Services/Category/list`);
                setCategories(res.data.results);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayCategory = () => {
        let display = [];
        let result = [];

        categories.map(category => {
            return display.push(
                <ServiceCard
                    id={category.id}
                    categoryname={category.categoryname}
                    description={category.description}
                    image={category.image}
                />
            );
        });

        for (let i = 0; i < categories.length; i += 3) {
            result.push(
                <div key={i} className="row">
                        {display[i]}
                        {display[i+1] ? display[i+1] : null}
                        {display[i+2] ? display[i+2] : null}
                </div>
            );
        }

        return result;
    };

        return (
            <div>
                <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8 text-center text-md-left">
                                <h2 className="f-700">Services</h2>
                            </div>
                            <div className="col-md-4 text-center text-md-right">
                                <ul className="pagination-inner">
                                    <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                    <li>Services</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="services pt-80 pb-80"> 
                    <div class="container">
                    {displayCategory()}
                    </div>
                </section>
            </div>
        );
    }
export default Services;

