import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import axios from 'axios';
import PriestCard from '../../components/PriestCard';

const MyAccount = () => {
    const [priests, setPriests] = useState([]);
    const [userDetails, setUserDetails] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("user");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/User/Profile/`+userDetails.user.id);
                setPriests(res.data.data);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayPriest = () => {
        let display = [];
        let result = [];

        priests.map(priest => {
            return display.push(
                <PriestCard
                    id={priest.id}
                    first_name={userDetails.user.first_name}
                    last_name={userDetails.user.last_name}
                    user_id={priest.user_id}
                    father_name={priest.father_name}
                    date_of_birth={priest.date_of_birth}
                    mobile_no={priest.mobile_no}
                    alternate_mobile_no={priest.alternate_mobile_no}
                    aadhaar_no={priest.aadhaar_no}
                    adderess={priest.adderess}
                    occupation={priest.occupation}
                    marital_status={priest.marital_status}
                    type_of_gurukal={priest.type_of_gurukal}
                    photo={process.env.REACT_APP_API_URL+"/media/"+priest.photo}
                    country={priest.country}
                    state={priest.state}
                    district={priest.district}
                    city={priest.city}
                    ID_proof={process.env.REACT_APP_API_URL+"/media/"+priest.ID_proof}
                    a_short_story_about_me={priest.a_short_story_about_me}
                    service_expertized={priest.service_expertized}
                    exprience={priest.exprience}
                    bank_account_details={priest.bank_account_details}
                    IFSC_code={priest.IFSC_code}
                    bank_passbook={process.env.REACT_APP_API_URL+"/media/"+priest.bank_passbook}
                    comments={priest.comments}
                    created_by={priest.created_by}
                />
            );
        });

        for (let i = 0; i < priests.length; i += 1) {
            result.push(
                <div key={i} className="row">
                        {display[i]}
                </div>
            );
        }

        return result;
    };

        return (
        <div>
        {/* <section class="inner-banner bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/images/Banner/banner1.jpg'})` }}>
            <h1 class="transform-center">About</h1>
        </section> */}
        <section class="bnr-pagination pt-45 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-8 text-center text-md-left">
                            <h2 class="f-700">About User</h2>
                        </div>
                        <div class="col-md-4 text-center text-md-right">
                            <ul class="pagination-inner">
                                <li><a href="index.html" class="text-custom-pink">Home </a>
                                </li>
                                <li>About User</li>
                            </ul>
                        </div>
                    </div>
                </div>
        </section>
        <section class="services pt-80 pb-80"> 
        <div class="container">
            <div className="col-12">
                <a href={'https://saptharishivedicservices.net/api/Priest/Edit_Priests/'+userDetails.user.id} target="_blank" className="btn btn-yellow">Edit Profile</a>
            </div>
            {displayPriest()}
        </div>
        {/* <div className="container">
            <div className="card">
                <div className="card-body">
                <Calendar events={events} />
                </div>
            </div>
        </div> */}
       </section>
       </div>
        );
    }

export default MyAccount;