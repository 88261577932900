import React, { Component } from 'react'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { register } from '../../Actions/auth.js';
import { Link, Redirect } from "react-router-dom";

class Register extends Component {

    state = {
        username: "",
        first_name: "",
        last_name: "",
        email:'',
        mobile:'',
        password: "",
        password2: '',
    }

    static propTypes ={
        register: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    }


    onSubmit = e => {
        e.preventDefault();
        const { username, first_name, last_name, email, mobile, password, password2 } = this.state;

        if (password !== password2) {
            alert("Passwords do not match")
        } else if (username == '' || email == '' || mobile == '' || password==''){
            alert("Please provide all the details")
        } else {
            const newUser = {
                username,
                password,
                first_name,
                last_name,
                email,
                mobile,
            };
            this.props.register(newUser);

            e.target.reset();

            /* alert("You have successfully registered!")
            window.location.href = "/login"; */
        }
    }

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <>
      {/* <section class="login-inner inner-banner bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Banner/banner-h1-b2.jpg"})` }}>
      <h1 className="transform-center">Registration</h1>
    </section> */}
    <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                    <h2 className="f-700">Register</h2>
                </div>
                <div className="col-md-4 text-center text-md-right">
                    <ul className="pagination-inner">
                        <li><Link to="/" className="text-custom-pink">Home </Link></li>
                        <li>Register</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section className="login-form login pt-80 pb-80">
        <div className="container">
            <div className="row no-gutters">
                <div className="col-xl-7 col-lg-6 d-none d-lg-block">
                    <div className="login-image bg-cover h-100" style={{ backgroundImage: `url('/assets/images/saptharishis_infish.jpg')` }}>

                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 ">
                    <div className="form-area bg-yellow">
                        <h2 className="f-700 mb-15 text-custom-white">Register Here</h2>
                        <p>Welcome to Saptharishi Vedic Services</p>
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group relative mb-25 mb-sm-20">
                                <input type="text" className="form-control bg-white input-lg input-white shadow-5" required placeholder="User Name *" onChange={e => this.setState({username: e.target.value})} />
                                <i className="fas fa-user transform-v-center"></i>
                            </div>
                            <div className="form-group relative mb-25 mb-sm-20">
                                <input type="text" className="form-control bg-white input-lg input-white shadow-5" placeholder="First Name" onChange={e => this.setState({first_name: e.target.value})} />
                                <i className="fas fa-user transform-v-center"></i>
                            </div>
                            <div className="form-group relative mb-25 mb-sm-20">
                                <input type="text" className="form-control bg-white input-lg input-white shadow-5" placeholder="Last Name" onChange={e => this.setState({last_name: e.target.value})} />
                                <i className="fas fa-user transform-v-center"></i>
                            </div>
                            <div className="form-group relative mb-25 mb-sm-20">
                                <input type="email" className="form-control bg-white input-lg input-white shadow-5" placeholder="E-Mail" onChange={e => this.setState({email: e.target.value})} />
                                <i className="fas fa-user transform-v-center"></i>
                            </div>
                            <div className="form-group relative mb-25 mb-sm-20">
                                <input type="text" className="form-control bg-white input-lg input-white shadow-5" required placeholder="Mobile No. with country code *" onChange={e => this.setState({mobile: e.target.value})} />
                                <i className="fas fa-user transform-v-center"></i>
                                <span className="text-muted">Eg.: +919876543210</span>
                            </div>
                            <div className="form-group relative mb-20 mb-sm-20">
                                <input type="password" className="form-control bg-white input-lg input-white shadow-5" required placeholder="Password *" onChange={e => this.setState({password: e.target.value})} />
                                <i className="fas fa-lock transform-v-center"></i>
                            </div>
                            <div className="form-group relative mb-20 mb-sm-20">
                                <input type="password" className="form-control bg-white input-lg input-white shadow-5" required placeholder="Confirm Password *" onChange={e => this.setState({password2: e.target.value})} />
                                <i className="fas fa-lock transform-v-center"></i>
                            </div>
                            <button type="submit" className="btn btn-black btn-block shadow-4 mt-20">Register</button>
                            <div className="signup-login text-center">
                                <p className="mt-15 fs-13">
                                Already a member? <Link to="/login" className="ml-5 mb-0 d-inline-block f-500 text-white">Login</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
            </>
        )
    }
}
export default connect(null, { register })(Register);
