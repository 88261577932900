import axios from 'axios';
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
} from './types';

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
    // User Loading
    dispatch({ type: USER_LOADING });

    const token = getState().AuthReducer.token;

    const config = {
        headers :{
            'Content-Type': 'application/json',
        }
    }

    if(token){
        config.headers['Authorization']=`Token ${token}`
    }

    axios
        .get(`${process.env.REACT_APP_API_URL}/api/user/`, config)
        .then((res) => {
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch({
                type: AUTH_ERROR,
            });
        });
};

//Login
export const login = (username, password) => dispatch => {

    const config = {
        headers :{
            'Content-Type': 'application/json',
        }
    }

    //Request body
    const body= JSON.stringify({ username, password })

    axios
        .post(`${process.env.REACT_APP_API_URL}/api/login/`, body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
            localStorage.setItem('user', JSON.stringify(res.data))
        })
        .catch((err) => {
            dispatch({
                type: LOGIN_FAIL,
            });
            alert("Invalid username or password")
        });
};

//Logout user
export const logout = () => (dispatch, getState) => {
    axios
        .post(`${process.env.REACT_APP_API_URL}/api/logout/`, null, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: 'CLEAR_LEADS' });
            dispatch({
                type: LOGOUT_SUCCESS,
            });
            localStorage.clear()
        })
        .catch((err) => {
        dispatch({
            type: AUTH_ERROR,
        });
    });
}

// Setup config with token - helper function
export const tokenConfig = (getState) => {
    // Get token from state
    const token = getState().AuthReducer.token;  
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    // If token, add to headers config
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
};

// REGISTER USER
export const register = ({ username, password, first_name, last_name, email, mobile }) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };
    // Request Body
    const body = JSON.stringify({ username, password, first_name, last_name, email, mobile });

    axios
        .post(`${process.env.REACT_APP_API_URL}/api/register/`, body, config)
        .then((res) => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            });
            alert('Registration Successful!');
        })
        .catch((err) => {
            dispatch({
                type: REGISTER_FAIL,
            });
            alert('Failed',err);
        });
};
