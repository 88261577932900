import React from 'react';
import { Link, Redirect } from 'react-router-dom';

class PrivacyReg extends React.Component {
    render() {
      return (
        <>
        <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
          <div className="container">
            <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                    <h2 className="f-700">Policy Regulations</h2>
                </div>
                <div className="col-md-4 text-center text-md-right">
                    <ul className="pagination-inner">
                        <li><Link to="/" className="text-custom-pink">Home </Link></li>
                        <li>Policy Regulations</li>
                    </ul>
                </div>
            </div>
          </div>
        </section>
        <div class="container">
        <div className="PrivacyReg">
          <h2 className="mt-20">Policy Regulations</h2>
          <p><br></br>
          <h2>About us</h2><br></br>
            This privacy policy sets out how Saptharishi Vedic Services uses and protects any information that you give our website. When you use this website.
            We, at Saptharishi Vedic Services, are committed to respecting your online privacy and recognize your need for appropriate protection and management of any personally identifiable information you share with us. “Personal Information” means any information that may be used to identify an individual, also including, a first and last name, a home or other physical address, contact number and an email address or other contact information.
            Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</p><br></br>
            <p><h2>Permissions</h2><br></br>
            Our primary goal in doing so is to provide you a safe, efficient, smooth and customised experience. By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.<br></br>
            ➤We require this information understand your needs and provide you with a better service, and in particular for the following reasons:<br></br>
            ➤We may use the information to improve our products and services<br></br>
            ➤We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided<br></br>
            ➤From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail.<br></br> 
            ➤We may use the information to customise the website according to your interests</p><br></br>
            <p><h2>Information We Collect?</h2><br></br>
            In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website.<br></br>
            1. For order, we need to know your name, e-mail address and mailing address. This allows us to process and fulfil your order and to notify you of your order status.<br></br>
            2. When you sign up for our Newsletter we need only an e-mail address – which we use to send the information you requested.<br></br>
            3. When you submit a customer review, we also ask for your e-mail address, although you can choose not to have your e-mail address displayed with your review.<br></br>
            4. We personalize your experience by using your purchases to shape our recommendations about the temples, deities, and pujas that might be of interest to you. We also monitor customer traffic patterns and site usage to help us develop the design and layout of the site.<br></br>
            5. If you choose to post messages on our message boards or other message areas or leave feedback, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.</p><br></br>
            <p><h2>Security</h2><br></br>
            We take appropriate security measures to protect against unauthorised access to or unauthorised alteration, disclosure or destruction of data.<br></br>
            We restrict access to your personally identifying information to employees who need to know that information in order to operate, develop or improve our services. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
            <p><h2>Confidentiality</h2><br></br>
            Sapatharishi Vedic Services does not rent, sell, or share personal information about you with other people (save with your permission) or non-affiliated companies except to provide products or services you’ve requested, when we have your permission, or under the following circumstances.<br></br>
            ➤We provide the information to trusted partners who work on our behalf of us under confidentiality agreements. These companies may use your personal information to help us to communicate with you about our offers from and our marketing partners. However, these companies do not have any independent right to share this information.<br></br>
            ➤We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims;<br></br>
            ➤We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law.<br></br> 
            ➤We transfer information about you if is acquired by or merged with another company. In this event, we will notify you before information about you is transferred and becomes subject to a different privacy policy.</p>
            <p><h2>Images</h2><br></br>
            All photo images on this site are believed to be public domain, they are gathered from all over the internet and there is no copyright on these pictures as far as we are concerned. If there is a picture on this site that has a copyright then the owner can email us and we will remove the picture from this site. None of the persons on this site have authorised their presence here. This site is not associated with them or their companies in any way. All trademarks belong to their respective owners.</p>
            <p><h2>Restrict Your Personal Information</h2><br></br>
            You may choose to restrict the collection or use of your personal information in the following ways:
            whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes
            if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us .
            We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
            You may request details of personal information which we hold about you under the Data Protection Act 1998. 
            If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.<br></br>
          </p>
        </div>
        </div>
        </>
      );
    }
  }

export default PrivacyReg;
