import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ServiceCard = (props) => {
    return (
    <div className="col-lg-4 col-md-6">
        <div className="shop-prdt shadow-5 mb-30">
            <div className="shop-prdt-img">
                <img src={props.service_image} alt="" />
                <div className="shop-buttons transform-center transition-4">
                {/*    {(props.category_name != "Temple Services") ? <a href={void(0)} data-toggle="modal" data-target={'#chooseDate'+props.id} className="btn btn-cart bg-yellow">ADD TO CART</a>:''}*/}
                <Link to={`/ServicesDetails/${props.id}`} className="btn btn-cart bg-yellow">ADD TO CART</Link>
                </div>
                <span></span>
            </div>
            <div className="shop-prdt-data">
                {/* <ul className="stars-rate mb-5" data-starsactive="3">
                    <li className="text-left lh-10"> <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </li>
                </ul> */}
                <h3 className="f-700 fs-14 mt-10 mb-2 lh-13"> {props.service_name}</h3>
                <p align="justify">{props.short_description}</p>
                <div className="hr-1 opacity-1 bg-black"></div>
                <div class="prdt-price">
                    <span class="real-price">{props.price_range}</span>
                    <span class="per-day">/ Per day</span>
                </div>
            </div>
        </div>
    </div>
    );
};

ServiceCard.propTypes = {
    id: PropTypes.number.isRequired,
    service_name: PropTypes.string.isRequired,
    short_description: PropTypes.string.isRequired,
    long_description: PropTypes.string.isRequired,
    price_range: PropTypes.string.isRequired,
    key_insight: PropTypes.string.isRequired,
    our_promises: PropTypes.string.isRequired,
    service_image: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    category_name: PropTypes.string.isRequired,
};

export default ServiceCard;
