// Blog.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EventCard from '../components/EventCard';
import Pagination from '../components/Pagination';
import { Link, Redirect } from 'react-router-dom';

const Event = () => {
    const [Events, setEvents] = useState([]);
    const [count, setCount] = useState(0);
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    const [active, setActive] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/Events/?page=1`);
                console.log(res.data.results);
                setEvents(res.data.results);
                setCount(res.data.count);
                setPrevious(res.data.previous);
                setNext(res.data.next);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayEvents = () => {
        let display = [];
        let result = [];

        Events.map(event => {
            return display.push(
                <EventCard
                    id={event.id}
                    title={event.title}
                    title_tag={event.title_tag}
                    short_description={event.short_description}
                    description={event.description}
                    day={event.day}
                    start_time={event.start_time}
                    end_time={event.end_time}
                    images={event.images}
                    notes={event.notes}
                />
            );
        });

        for (let i = 0; i < Events.length; i += 3) {
            result.push(
                <div key={i} className="row">
                        {display[i]}
                        {display[i+1] ? display[i+1] : null}
                        {display[i+2] ? display[i+2] : null}
                </div>
            );
        }

        return result;
    };

    const visitPage = (page) => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/Events/?page=${page}`)
        .then(res => {
            setEvents(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            setActive(page);
        })
        .catch(err => {

        });
    };

    const previous_number = () => {
        axios.get(previous)
        .then(res => {
            setEvents(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            if (previous)
                setActive(active-1);
        })
        .catch(err => {

        });
    };

    const next_number = () => {
        axios.get(next)
        .then(res => {
            setEvents(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            if (next)
                setActive(active+1);
        })
        .catch(err => {

        });
    };

    return (
        <div>
            <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 text-center text-md-left">
                            <h2 className="f-700">Services</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-right">
                            <ul className="pagination-inner">
                                <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- work gallery start --> */}
        <section className="blog-listing our-articles pt-80 pb-80">
            <div className="container">
                {displayEvents()}
                <div className="row">
                        <div className="col-12 text-center">
                            <div className="pagination-sml no-border pt-10 text-center">
                                <Pagination
                                    itemsPerPage={3}
                                    count={count}
                                    visitPage={visitPage}
                                    previous={previous_number}
                                    next={next_number}
                                    active={active}
                                    setActive={setActive}
                                />
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        {/* <!-- work gallery end --> */}
    </div>
    );
};


export default Event;

