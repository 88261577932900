// Footer.js
import React, {Component} from 'react';

export default class Footer extends Component {
    render(){
        return (
            /* <!-- footer start --> */
    <footer className="bg-light-white relative">
        <div className="footer-style pt-80 pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-box mb-md-80">
                            <div className="ft-logo mb-xl-20">
                                <a href="index.html">
                                    <img src="assets/images/AMF/AMF1.png" className="img-fluid" alt="img"/>
                                </a>
                            </div>
                            <p className="text-custom-black mb-xl-20" align="justify">AMF provide various types of social services to the community around Shiva Temples in remote villages in & out of Tamil Nadu, India and across the World. Helping poor children's for basic requirements like food, clothes and educational aids, Medical expenses for elderly poor people.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-box mb-md-80">
                            <div className="ft-head">
                                <h5 className="fs-23 f-700 mb-xl-20">Contact</h5>
                            </div>
                            <ul className="custom contact-detail">
                                <li>
                                    <a href="#" className="text-custom-black fs-14">
                                        <i className="fas fa-user text-custom-pink"></i>
                                        <span className="ml-2">Souvami Sundareson</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-custom-black fs-14">
                                        <i className="fas fa-phone-alt text-custom-pink"></i>
                                        <span className="ml-2">+91 97416 48485</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-custom-black fs-14">
                                        <i className="fas fa-map-marker-alt text-custom-pink"></i>
                                        <span className="ml-2">345A, 17A Main, 22nd Cross, <br/> 3rd Sector HSR Layout,<br/> Bangalore - 560 102 </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-custom-black fs-14">
                                        <i className="fas fa-envelope text-custom-pink"></i>
                                        <span className="ml-2">Swami@andharmugam.com</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="text-custom-black fs-14">
                                        <i className="fas fa-globe text-custom-pink"></i>
                                        <span className="ml-2">https://andharmugam.com/</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-box mb-xs-80">
                            <div className="ft-head">
                                <h5 className="fs-23 f-700 mb-xl-20">Regulations</h5>
                            </div>
                            <div className="temple-events">
                                <div className="event-box mb-10">
                                    <h6 className="mb-1 fs-18"><a href="/PrivacyReg">Privacy Regulations</a></h6>
                                </div>
                                <div className="event-box mb-10">
                                    <h6 className="mb-1 fs-18"><a href="#">Terms of use</a></h6>
                                </div>
                                <div className="event-box mb-10">
                                    <h6 className="mb-1 fs-18"><a href="/FAQ">FAQ</a></h6>
                                </div>
                                <div className="event-box mb-10">
                                    <h6 className="mb-1 fs-18"><a href="/CancelRefundPolicy">Cancellation & Refund Policy</a></h6>
                                </div>
                                <div className="event-box">
                                    <h6 className="mb-1 fs-18"><a target="_blank" href="https://saptharishivedicservices.net/api/Priest/Register/As/Priest">Register as Purohit</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="footer-box">
                            <div className="ft-head">
                                <h5 className="fs-23 f-700 mb-xl-20">Event Galary</h5>
                            </div>
                            <ul className="custom instagram">
                                <li class="col-sm-6 p-0">
                                    <a href="#">
                                        <img src="assets/images/AMF/IMG-20191127-WA0027.jpg" className="image-fit" width="100" height="100" alt="Event"/>
                                    </a>
                                    <div className="insta-icon">
                                        <a href="#">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </li>
                                <li class="col-sm-6 p-0">
                                    <a href="#">
                                        <img src="assets/images/AMF/IMG-20191127-WA0028.jpg" className="image-fit" width="100" height="100" alt="Event"/>
                                    </a>
                                    <div className="insta-icon">
                                        <a href="#">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </li>
                                <li class="col-sm-6 p-0">
                                    <a href="#">
                                        <img src="assets/images/AMF/IMG-20191127-WA0029.jpg" className="image-fit"  width="100" height="100" alt="Event"/>
                                    </a>
                                    <div className="insta-icon">
                                        <a href="#">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </li>
                                <li class="col-sm-6 p-0">
                                    <a href="#">
                                        <img src="assets/images/AMF/IMG-20191127-WA0030.jpg" className="image-fit"  width="100" height="100"  alt="Event"/>
                                    </a>
                                    <div className="insta-icon">
                                        <a href="#">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </li>
                                <li class="col-sm-6 p-0">
                                    <a href="#">
                                        <img src="assets/images/AMF/IMG-20191127-WA0031.jpg" className="image-fit"  width="100" height="100" alt="Event"/>
                                    </a>
                                    <div className="insta-icon">
                                        <a href="#">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </li>
                                <li class="col-sm-6 p-0">
                                    <a href="#">
                                        <img src="assets/images/AMF/IMG-20191127-WA0032.jpg" className="image-fit"  width="100" height="100"  alt="Event"/>
                                    </a>
                                    <div className="insta-icon">
                                        <a href="#">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-footer pb-35">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <div className="hr-2 mb-35 bg-black opacity-1"></div>
                    </div>
                    <div className="col-lg-6 col-md-12 text-center text-lg-left mb-md-10">
                        <ul className="social-icons footer-social">
                            <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                            </li>
                            <li> <a href="#"><i className="fab fa-twitter"></i></a>
                            </li>
                            <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                            </li>
                            <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-12 text-center text-lg-right">© Saptharishi Vedic Services {new Date().getFullYear()} Allright Reserved</div>
                </div>
            </div>
            <a href="#" className="btn scroll-btn f-right flex-center z-25 opacity-0"> <i className="fas fa-arrow-up"></i>
            </a>
        </div>
    </footer>
    /* <!-- footer end --> */
        );
    }
}