import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Calendar from 'react-awesome-calendar';

const PriestCard = (props) => {

    return(
        <div className="col-md-12">
        <section class="about-me2 pt-10 pb-10 pb-lg-60">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 z-5">
                    <div class="about-image relative shadow-1 js-tilt mb-md-40">
                        <img src={props.photo} alt="" />
                        <div class="user-exprnce mb-50 ml-50 mb-xs-10 ml-xs-00">
                            <h3 class="yellow fs-85 f-700 mb-10">{props.exprience}</h3>
                            <h4 class="black f-700 fs-24">Years of Experience</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 pl-30 pl-lg-15 z-5">
                    <h2 class="f-700 fs-41 mb-30">{props.first_name+' '+props.last_name}</h2>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th colSpan="8"><h3 class="yellow fs-85 f-700 mb-10">Personal Details</h3></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>First Name</th>
                                <td>{props.first_name}</td>
                                <th>Last Name</th>
                                <td>{props.last_name}</td>
                            </tr>
                            <tr>
                                <th>Father Name</th>
                                <td>{props.father_name}</td>
                                <th>DOB</th>
                                <td>{props.date_of_birth}</td>
                            </tr>
                            <tr>
                                <th>Mobile No.</th>
                                <td>{props.mobile_no}</td>
                                <th>Address</th>
                                <td>{props.adderess}</td>
                            </tr>
                            <tr>
                                <th>Occupation</th>
                                <td>{props.occupation}</td>
                                <th>Marital Status</th>
                                <td>{props.marital_status}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    </section>
    <section class="about-me2 pb-80 pb-lg-60">
        <div class="container">
            <div class="simple-quote pl-25 mt-20 mb-35">
                <p class="fs-15 rbt-slab lh-18">
                    
                </p>
            </div>
            <h3 class="f-700 mb-15 fs-20">A Short Story About Me</h3>
            <p class=" mb-30">{props.a_short_story_about_me}
            </p>
            <h3 class="f-700 mb-15 fs-20">Expertise In ({props.type_of_gurukal})</h3>
            <p>{props.service_expertized}</p>
            <ul class="yello-dot-list">
                <li>Aenean vehicula molestie aliquet. Maecenas sed pretium</li>
                <li>Nulla malesuada id ipsum quis vestibulum. In in sapien</li>
                <li>Fringilla, gravida nisl sit amet, consequat turpis.</li>
                <li>Praesent at gravida dolor. Duis tristique sit amet lacus</li>
                <li>Nulla malesuada id ipsum quis vestibulum. In in sapien</li>
            </ul>
        </div>
    </section>
    {/* <section class="about-texts pb-80">
        <div class="container">
            <div class="row">
                <div class="col-12 pb-80">
                    <div class="hr-2 bg-black opacity-1"></div>
                </div>
                <div class="col-lg-4">
                    <h3 class="lined-head fs-28 f-700 mb-md-45">What I Do</h3>
                    <span class="cont-fade">01</span>
                </div>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-md-4 col-sm-6 mb-sm-30">
                            <div class="wht-do-each js-tilt2">
                                <img src="assets/images/about_us/sermons.jpg" class="opacity-5" alt="" />
                                <h4 class="transform-center text-center">
                                    <a href="sermons-detail.html" class="rbt-slab f-700 lh-16 fs-20 text-dark-red">Weekly Sermons</a>
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-sm-30">
                            <div class="wht-do-each js-tilt2">
                                <img src="assets/images/about_us/poojas.jpg" class="opacity-2" alt="" />
                                <h4 class="transform-center text-center">
                                    <a href="sermons-detail.html" class="rbt-slab f-700 lh-16 fs-20 text-dark-red">Daily Poojas</a>
                                </h4>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-sm-30">
                            <div class="wht-do-each js-tilt2">
                                <img src="assets/images/about_us/yaga.jpg" class="opacity-2" alt="" />
                                <h4 class="transform-center text-center">
                                    <a href="sermons-detail.html" class="rbt-slab f-700 lh-16 fs-20 text-dark-red">Conducting Homam</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-65 pt-sm-40 pb-65">
                    <div class="hr-2 bg-black opacity-1"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <h3 class="lined-head fs-28 f-700 mb-md-45">Experience</h3>
                    <span class="cont-fade">{props.exprience}</span>
                </div>
                <div class="col-lg-8">
                    <ul class="experience">
                        <li>
                            <div class="dates">
                                <h4 class="f-700 fs-18 yellow">2017</h4>
                                <p>Sep 18</p>
                            </div>
                            <div class="experience-text">
                                <h4 class="fs-17 f-700 mb-10">Aliquam erat volutpaat orbi euismod</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquam, tortor quis mollis interdum, tortor felis iaculis magna.</p>
                            </div>
                        </li>
                        <li>
                            <div class="dates">
                                <h4 class="f-700 fs-18 yellow">2010</h4>
                                <p>Feb 05</p>
                            </div>
                            <div class="experience-text">
                                <h4 class="fs-17 f-700 mb-10">Fusce bibendum gravida semper.</h4>
                                <p>Cras porta tortor id erat fermentum, vitae pretium metus euismod. Quisque sit amet ipsum quis quam tincidunt feugiat. Fusce bibendum gravida semper.rus eget augue</p>
                            </div>
                        </li>
                        <li>
                            <div class="dates">
                                <h4 class="f-700 fs-18 yellow">2005</h4>
                                <p>Mar 09</p>
                            </div>
                            <div class="experience-text">
                                <h4 class="fs-17 f-700 mb-10">Morbi euismod eros magna, quis</h4>
                                <p>Aliquam erat volutpat. Morbi euismod eros magna, quis feugiat lectus euismod vel. Nunc rutrum dolor sed faucibus congue. Nunc pulvinar mattis orci.</p>
                            </div>
                        </li>
                        <li>
                            <div class="dates">
                                <h4 class="f-700 fs-18 yellow">2001</h4>
                                <p>Apr 20</p>
                            </div>
                            <div class="experience-text">
                                <h4 class="fs-17 f-700 mb-10">Fusce bibendum gravida semper.</h4>
                                <p>Cras porta tortor id erat fermentum, vitae pretium metus euismod. Quisque sit amet ipsum quis quam tincidunt feugiat. Fusce bibendum gravida semper.rus eget augue</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-12 pt-45 pb-65">
                    <div class="hr-2 bg-black opacity-1"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <h3 class="lined-head fs-28 f-700 mb-md-45">Faq</h3>
                    <span class="cont-fade">02</span>
                </div>
                <div class="col-lg-8">
                    <ul class="faq-list">
                        <li> <span class="count-num yellow rbt-slab fs-21 f-700">Q.1</span>
                            <div class="faq-list-text">
                                <h4 class="fs-18 f-700 mb-10">How can I join the Temple?</h4>
                                <p>Cras porta tortor id erat fermentum, vitae pretium metus euismod. Quisque sit amet ipsum quis quam tincidunt feugiat. Fusce bibendum gravida semper.rus eget augue</p>
                            </div>
                        </li>
                        <li> <span class="count-num yellow rbt-slab fs-21 f-700">Q.2</span>
                            <div class="faq-list-text">
                                <h4 class="fs-18 f-700 mb-10">Where is the Temple located?</h4>
                                <p>Cras porta tortor id erat fermentum, vitae pretium metus euismod. Quisque sit amet ipsum quis quam tincidunt feugiat. Fusce bibendum gravida semper.rus eget augue</p>
                            </div>
                        </li>
                        <li> <span class="count-num yellow rbt-slab fs-21 f-700">Q.3</span>
                            <div class="faq-list-text">
                                <h4 class="fs-18 f-700 mb-10">What is the Temple Sunday service schedule?</h4>
                                <p>Cras porta tortor id erat fermentum, vitae pretium metus euismod. Quisque sit amet ipsum quis quam tincidunt feugiat. Fusce bibendum gravida semper.rus eget augue</p>
                            </div>
                        </li>
                        <li> <span class="count-num yellow rbt-slab fs-21 f-700">Q.4</span>
                            <div class="faq-list-text">
                                <h4 class="fs-18 f-700 mb-10">How can I support the Temple?</h4>
                                <p>Cras porta tortor id erat fermentum, vitae pretium metus euismod. Quisque sit amet ipsum quis quam tincidunt feugiat. Fusce bibendum gravida semper.rus eget augue</p>
                            </div>
                        </li>
                    </ul> <a href="#" class="faq-more fs-13 f-700 transform-v-center">
                    SHOW ME MORE<i class="fas fa-long-arrow-alt-right ml-20"></i>
              </a>
                </div>
                <div class="col-12 pt-50 pb-60">
                    <div class="hr-2 bg-black opacity-1"></div>
                </div>
            </div>
        </div>
    </section> */}
    {/*<div className="container">
    <div className="card">
        <div className="card-body">
        <Calendar
            events={events}
        />
        </div>
    </div>
        </div>*/}
    </div>
    );
};

PriestCard.propTypes = {
    id:PropTypes.number.isRequired,
    user_id:PropTypes.number.isRequired,
    first_name:PropTypes.string.isRequired,
    last_name:PropTypes.string.isRequired,
    father_name:PropTypes.string.isRequired,
    date_of_birth:PropTypes.string.isRequired,
    mobile_no:PropTypes.string.isRequired,
    alternate_mobile_no:PropTypes.string.isRequired,
    aadhaar_no:PropTypes.string.isRequired,
    adderess:PropTypes.string.isRequired,
    occupation:PropTypes.string.isRequired,
    marital_status:PropTypes.string.isRequired,
    type_of_gurukal:PropTypes.string.isRequired,
    photo:PropTypes.string.isRequired,
    country:PropTypes.string.isRequired,
    state:PropTypes.string.isRequired,
    district:PropTypes.string.isRequired,
    city:PropTypes.string.isRequired,
    ID_proof:PropTypes.string.isRequired,
    a_short_story_about_me:PropTypes.string.isRequired,
    service_expertized:PropTypes.string.isRequired,
    exprience:PropTypes.string.isRequired,
    bank_account_details:PropTypes.string.isRequired,
    IFSC_code:PropTypes.string.isRequired,
    bank_passbook:PropTypes.string.isRequired,
    comments:PropTypes.string.isRequired,
};

export default PriestCard;