import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ServiceCard = (props) => {
    return (
        <div class="col-lg-4">
            <div class="work-item service-list shadow-1 mb-30 relative" style={{ backgroundImage: `url(${props.image})` }}>
                <div class="overlay-full"></div>
                <div class="work-text-2 white">
                    <h3 class="mb-15"><a href="services-detail.html">{props.categoryname}</a></h3>
                    <p class="mb-5">{props.description}</p> <span class="line-under bg-yellow"></span> <br />
                    <span className="text-warning float-right">* Online Service Available</span>
                </div>
                <Link to={`/servicesList/${props.id}/${props.categoryname}`} class="laink-arrow transform-center"> <i class="fas fa-arrow-right"></i>
                </Link>
            <div class="js-tilt-glare"></div></div>
        </div>
    );
};

ServiceCard.propTypes = {
    id: PropTypes.number.isRequired,
    categoryname: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default ServiceCard;
