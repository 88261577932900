import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../Actions/auth';

export class Login extends Component {
  state = {
    username: '',
    password: '',
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      if (localStorage.getItem("request_from") === null) {
        return <Redirect to="/home" />;
      }else{
        var url=localStorage.getItem("request_from");
        return <Redirect to={url} />;
      }
      
    }
    const { username, password } = this.state;
    return (
      <>
      {/* <section className="login-inner inner-banner bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Banner/banner1.jpg"})` }}>
      <h1 className="transform-center">LOGIN</h1>
    </section> */}
    <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                    <h2 className="f-700">Login</h2>
                </div>
                <div className="col-md-4 text-center text-md-right">
                    <ul className="pagination-inner">
                        <li><Link to="/" className="text-custom-pink">Home </Link></li>
                        <li>Login</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section className="login-form login pt-80 pb-80">
        <div className="container">
            <div className="row no-gutters">
                <div className="col-xl-7 col-lg-6 d-none d-lg-block">
                    <div className="login-image bg-cover h-100" style={{ backgroundImage: `url('/assets/images/saptharishis_infish.jpg')` }}>

                    </div>
                </div>
                <div className="col-xl-5 col-lg-6 ">
                    <div className="form-area bg-yellow">
                        <h2 className="f-700 mb-15 text-custom-white">Login Here</h2>
                        <p>Welcome to Saptharishi Vedic Services</p>
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group relative mb-25 mb-sm-20">
                                <input type="text" className="form-control bg-white input-lg input-white shadow-5" name="username" onChange={this.onChange} placeholder="Username" value={username} />
                                <i className="far fa-user transform-v-center"></i>
                            </div>
                            <div className="form-group relative mb-20 mb-sm-20">
                                <input type="password" className="form-control bg-white input-lg input-white shadow-5" name="password" onChange={this.onChange} placeholder="Password" value={password} />
                                <i className="fas fa-lock transform-v-center"></i>
                            </div>
                            <button type="submit" className="btn btn-black btn-block shadow-4 mt-20">LOGIN</button>
                            <div className="signup-login text-center">
                                <p className="mt-15 fs-13">
                                    Don't have an account? <Link to="/register" className="ml-5 mb-0 d-inline-block f-500 text-white">Register</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.AuthReducer.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
