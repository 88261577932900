// Topbar.js
import React, {Component} from 'react';
import Logout from '../pages/Auth/Logout';
export default class Topbar extends Component {
    render(){
        return (
            <div className="topbar">
                <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-5 col-sm-12 col-4">
                        <div className="top-link left-side">
                            <ul className="custom">
                                <li>
                                    <a href="tel:"><i className="fas fa-phone-alt"></i> (+91) 97416 48485</a>
                                </li>
                                <li>
                                    <a href="mailto:inquiry@inesssolutions.com"><i className="fas fa-envelope"></i> inquiry@inesssolutions.com</a>
                                </li>
                                <li>
                                    <a href="#"><i className="fas fa-map-marker-alt"></i> Chennai, Tamilnadu</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-12 col-4">
                        <div className="top-link center">
                            <ul className="custom">
                                <li>
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 col-4">
                        <Logout />
                    </div>
                </div>
            </div>
        </div>

        );
    }
}