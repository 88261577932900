// Contacts.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { setAlert } from '../Actions/alert';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

const Contact = ({ setAlert }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        contact: '',
        message: ''
    });

    const { name, email, subject, contact, message } = formData;

    const [loading, setLoading] = useState(false);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/contacts/`, { name, email, subject, contact, message }, config)
        .then(res => {
            setAlert('Message Sent', 'success');
            setLoading(false);
            window.scrollTo(0, 0);
        })
        .catch(err => {
            setAlert('Error with Sending Message', 'error');
            setLoading(false);
            window.scrollTo(0, 0);
        })
    };

    return (
        <>
            <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 text-center text-md-left">
                            <h2 className="f-700">Contact Us</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-right">
                            <ul className="pagination-inner">
                                <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-fl pt-80 pb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-md-45">
                            <div className="auther-img auther-res bg-cover h-100" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/images/Banner/event-inner-banner-1e.jpg'})` }}></div>
                        </div>
                        <div className="col-lg-8">
                            <div className="contact-info pl-20 pl-md-00">
                                <h2 className="f-700 mb-30">Contact Information</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-xl-4 col-md-4">
                                        <div className="each-ctnct-info mb-sm-30">
                                            <div className="ctnct-info-icon"> <i className="fas fa-phone"></i>
                                            </div>
                                            <h5 className="fs-14 f-600 mb-10">CALL US</h5>
                                            <p className="bld-text"> <a href="#" className="fs-15 f-500">+123 456 7890</a>
                                                <a href="#" className="fs-15 f-500">+987 654 3210</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 col-md-4">
                                        <div className="each-ctnct-info mb-sm-30">
                                            <div className="ctnct-info-icon"> <i className="fas fa-envelope"></i>
                                            </div>
                                            <h5 className="fs-14 f-600 mb-10">SEND A MAIL</h5>
                                            <p className="bld-text"> <a href="#" className="fs-15 f-500">info@example.com</a>
                                                <a href="#" className="fs-15 f-500">trinity@example.com</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-xl-4 col-md-4 mt-lg-40 mt-md-00">
                                        <div className="each-ctnct-info">
                                            <div className="ctnct-info-icon"> <i className="fas fa-map-marker-alt"></i>
                                            </div>
                                            <h5 className="fs-14 f-600 mb-10">OUR ADDRESS</h5>
                                            <p className="bld-text"> <a href="#" className="fs-15 f-500">2663  Lodgeville Road Minnea, 55402, USA</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="hr-2 mt-40 mb-40 bg-black opacity-1"></div>
                                    </div>
                                </div>
                                <h2 className="f-700 mb-30">Send Me a Message</h2>
                                <form className='contact__form' onSubmit={e => onSubmit(e)}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group relative mb-30">
                                                <input className='form-control input-white black-border' name='name' type='text' placeholder='Enter Full Name' onChange={e => onChange(e)} value={name} required /> <i className="far fa-user transform-v-center"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group relative mb-30"> 
                                                <input className='form-control input-white black-border' name='email' type='email' placeholder='Enter your email' onChange={e => onChange(e)} value={email} required /> <i className="far fa-envelope transform-v-center"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group relative mb-30">
                                                <input className='form-control input-white black-border' name='subject' type='text' placeholder='Enter Subject' onChange={e => onChange(e)} value={subject} required /> <i className="fas fa-book-open transform-v-center"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group relative mb-30">
                                                <input className='form-control input-white black-border' name='contact' type='text' placeholder='Enter Mobile No.' onChange={e => onChange(e)} value={contact} required /> <i className="fas fa-phone-volume transform-v-center"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group textarea-min  relative mb-30">
                                                <textarea className='form-control input-white black-border mb-30' name='message' cols='30' rows='10' placeholder="Your message" placeholder='Message' onChange={e => onChange(e)} value={message} /> <i className="far fa-comment-dots transform-v-center"></i>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 mt-5"> <button type="submit" className="btn btn-yellow shadow-1">SUBMIT</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
    );
};

Contact.propTypes = {
    setAlert: PropTypes.func.isRequired
};

export default connect(null, { setAlert })(Contact);

