// Header.js
import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class Header extends Component {
    render(){
        return (
            <header className="type4 transition-5 relative mg-header mg-header-dark">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-2 col-4">
                    <div className="logo2">
                        <Link to="/">
                            <img src="Logo-Horizontal-h120.png" alt="Brand Logo" />
                        </Link>
                    </div>
                </div>
                <div className="col-md-8 col-4 text-right ">
                    <span className="toggle-category">
                        <i className="fas fa-chevron-down"></i>
                      </span>
                    <ul className="nav nav-links1 list-type2 justify-content-center">
                        {/* <li className="mg-nav-item mg-nav-item-has-children nav-link six-link active">
                            <a href="#">HOME</a>
                            <ul className="sub-menu">
                                <li className="mg-nav-item nav-link"> <a href="index.html">Homepage 1</a></li>
                                <li className="mg-nav-item nav-link"> <a href="homepage-2.html">Homepage 2</a></li>
                                <li className="mg-nav-item nav-link"> <a href="homepage-3.html">Homepage 3</a></li>
                                <li className="mg-nav-item nav-link"> <a href="homepage-4.html">Homepage 4</a></li>
                                <li className="mg-nav-item nav-link"> <a href="homepage-5.html">Homepage 5</a></li>
                                <li className="mg-nav-item nav-link"> <a href="homepage-6.html">Homepage 6</a></li>
                            </ul>
                        </li> */}
                        <li className="nav-link"><Link to="/">HOME</Link></li>
                        <li className="nav-link"><Link to="/about">About Us</Link></li>
                        <li className="nav-link"><Link to="/events">EVENTS</Link></li>
                        <li className="nav-link"><Link to="/blog">BLOG</Link></li>
                        <li className="nav-link"><Link to="/services">SERVICES</Link></li>
                        <li className="nav-link"><Link to="/contacts">CONTACTS</Link></li>


                        {/* <li className="mg-nav-item mg-nav-item-has-children nav-link">
                            <a href="#">SHOP</a>
                            <ul className="sub-menu">
                                <li className="mg-nav-item nav-link"> <a href="shop-listing.html">Shoping-list-1</a></li>
                                <li className="mg-nav-item nav-link"> <a href="shop-listing-2.html">Shoping-list 2</a></li>
                                <li className="mg-nav-item nav-link"> <a href="product-details.html">product-details</a></li>
                                <li className="mg-nav-item nav-link"> <a href="cart.html">cart</a></li>

                            </ul>
                        </li> */}
                    </ul>
                </div>
                <div className="col-md-2 col-4 text-center">
                    <a href="#" className="search-icon mr-150 mr-md-75 mr-xs-55" data-toggle="modal" data-target="#searchModal"> <i className="fas fa-search fs-18 white mt-5"></i>
                    </a>
                </div>
            </div>
        </div>
        <a href="/services" className="db-block ml-35 ml-xs-25">
            Book a Service
        </a>
        <a href="#" className="menu-bars db-none right-menu ml-35 ml-xs-25">
            <div className="bars transform-center">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>
        <div className="col-md-2 col-2 text-right">
            <div className="icon-links d-flex align-items-center justify-content-end">
                <div className="bg-menu-overlay transition-5 opacity-8"></div>
                <div className="fx-menu-wrapper bg-yellow-op-9 transition-5 d-flex justify-content-between flex-column">
                    <div className="fx-menu-header d-flex justify-content-between">
                        <p className="rbt-slab fs-13 f-700">Saptharishi Vedic Services</p>
                        <div className="close-menu">
                            <a href="#" className=""> <span></span>
                                <span></span>
                            </a>
                        </div>
                    </div>
                    <Link to="/services" className="btn btn-black mt-30 mb-35 mb-xs-20">Book a Service</Link>
                    <div className="fx-menu-content mb-15">
                        <ul className="fx-menu-links rbt-slab">
                            <li><Link to="/">HOME</Link></li>
                        </ul>
                        <ul className="fx-menu-links rbt-slab">
                            <li><Link to="/about">About Us</Link></li>
                        </ul>
                        <ul className="fx-menu-links rbt-slab">
                            <li><Link to="/events">EVENTS</Link></li>
                        </ul>
                        <ul className="fx-menu-links rbt-slab">
                            <li><Link to="/blog">BLOG</Link></li>
                        </ul>
                        <ul className="fx-menu-links rbt-slab">
                            <li><Link to="/services">SERVICES</Link></li>
                        </ul>
                        <ul className="fx-menu-links rbt-slab">
                            <li><Link to="/contacts">CONTACTS</Link></li>
                        </ul>

                        {/* <ul className="fx-menu-links rbt-slab">
                            <li> <a href="#" className="has-sub">SHOP</a>
                                <ul className="submenu">
                                    <li className="mg-nav-item nav-link"> <a href="shop-listing.html">Shoping-list 1</a></li>
                                    <li className="mg-nav-item nav-link"> <a href="shop-listing-2.html">Shoping-list 2</a></li>
                                    <li className="mg-nav-item nav-link"> <a href="product-details.html">product-details</a></li>
                                    <li className="mg-nav-item nav-link"> <a href="cart.html">cart</a></li>
                                </ul>
                            </li>
                        </ul> */}
                    </div>
                    <div className="fx-menu-footer">
                        <ul className="mg-header social-icons menu-social black">
                            <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                            </li>
                            <li> <a href="#"><i className="fab fa-twitter"></i></a>
                            </li>
                            <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
        );
    }
}