// About.js
import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
export default class About extends Component {
    render(){
        return (
            <div>
                <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8 text-center text-md-left">
                                <h2 className="f-700">About Us</h2>
                            </div>
                            <div className="col-md-4 text-center text-md-right">
                                <ul className="pagination-inner">
                                    <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
    {/* <!-- About Us start --> */}
    <section className="team-about pt-80 pb-80 pb-sm-60">
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">We are worship Team</h5>
                    <h3 className="text-dark-red fw-700 fs-41">Worship Team</h3>
                </div>
                <div className="section-description">
                    <p className="text-custom-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="photo-collage">
                        <div className="photo-left shadow-1">
                            <img src="https://via.placeholder.com/380x315" alt=""/>
                        </div>
                        <div className="photo-center transform-h-center shadow-1 z-10">
                            <img src="https://via.placeholder.com/380x315" alt=""/>
                        </div>
                        <div className="photo-right shadow-1">
                            <img src="https://via.placeholder.com/380x315" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- About Us end -->  */}
    {/* <!-- about info start -->  */}
    <section className="pb-80">
        <div className="container">
            <div className="row text-center text-lg-left">
                <div className="col-lg-6">
                    <h3 className="lh-15 mb-md-20 mt-xs-10">Vivamus non vulputate metus, in bibendum sapien. Nam rhoncus ipsum in nibh efficitur malesuada. Praesent sed purus rutrum, porta sem a, euismod diam. Curabitur ante metus, iaculis nec elit sit amet.</h3>
                </div>
                <div className="col-lg-5 offset-lg-1">
                    <p>Proin interdum nulla blandit rutrum vulputate. Maecenas vel erat at lacus accumsan lacinia id sed dolor. In sit amet vestibulum odio. Curabitur at dictum metus.</p>
                    <p className="mb-0">Nam ac laoreet ligula, non laoreet augue. Donec lobortis rhoncus luctus. Aenean nibh mauris, molestie ut libero vitae,ulla blandit rutrum vulputate. Maecenas</p>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- about info end -->  */}
    {/* <!-- video start -->  */}
    <section className="video-start pt-80 pb-80" style={{ backgroundImage: `url('https://via.placeholder.com/1920x430')` }} data-overlay="2">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <div className="our-style-text z-10">
                        <a href="https://www.youtube.com/watch?v=qtQgbdmIO30" className="laink-arrow opacity-1 z-10 popup-video  mb-35"> <i className="fas fa-play"></i>
                        </a>
                        <h3 className="text-custom-pink fs-58 f-700 mb-25">Weekly Sermon</h3>
                        <p className="white">Proin interdum nulla blandit rutrum vulputate. Maecenas vel erat at lacus accumsan lacinia id sed dolor.</p> <span className="line-vd"></span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- video end -->  */}
    {/* <!-- About Pastor start -->  */}
    <section className="pt-80 pb-80 bg-light-white">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 z-5">
                    <div className="about-image relative shadow-1 js-tilt mb-md-40">
                        <img src="https://via.placeholder.com/445x626" className="" alt=""/>
                        <div className="user-exprnce mb-50 ml-50 mb-xs-10 ml-xs-00">
                            <h3 className="yellow fs-85 f-700 mb-10">15</h3>
                            <h4 className="white f-700 fs-24">Years of Experience</h4>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 pl-30 pl-lg-15 z-5">
                    <h3 className="f-700 fs-41 mb-30">Robertson Alex</h3>
                    <div className="simple-quote pl-25 mb-30">
                        <p className="fs-15 rbt-slab">Worship is a way of feeling, of touching, of loving. What you have caught on film is captured forever… It remembers little things, long after you have forgotten everything.</p>
                    </div>
                    <h4 className="f-700 mb-15 fs-20">A Short Story About Me</h4>
                    <p className=" mb-30">Maecenas non faucibus nibh. Duis et eleifend sem, ut venenatis quam. Pelle ntesque eu quam ac arcu elementum vestibulum quis eu nulla. Donec lacinia risus ac ex mollis, sed faucibus magna pellentesque. Praesent volutpat mi ut leo vehicula,
                        iaculis finibus leo fermentum...
                    </p>
                    <h4 className="f-700 mb-15 fs-20">Why Choose My Temple?</h4>
                    <ul className="yello-dot-list">
                        <li>Aenean vehicula molestie aliquet. Maecenas sed pretium</li>
                        <li>Nulla malesuada id ipsum quis vestibulum. In in sapien</li>
                        <li>Fringilla, gravida nisl sit amet, consequat turpis.</li>
                        <li>Praesent at gravida dolor. Duis tristique sit amet lacus</li>
                        <li>Nulla malesuada id ipsum quis vestibulum. In in sapien</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- About Pastor End -->  */}
    {/* <!-- team start -->  */}
    <section className="team-inner pt-80 pb-80">
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">Pastors</h5>
                    <h3 className="text-dark-red fw-700 fs-41">Our Great Pastors</h3>
                </div>
                <div className="section-description">
                    <p className="text-custom-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="team-img shadow-1 js-tilt3">
                        <img src="https://via.placeholder.com/255x380" alt=""/>
                        <div className="team-text text-center">
                            <h4 className="fs-22 f-700 text-custom-pink mb-10">Jacob Dahan</h4>
                            <p className="white mb-0">Lead Pastor</p>
                            <div className="social-team">
                                <ul className="social-icons white">
                                    <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="team-img shadow-1 js-tilt3">
                        <img src="https://via.placeholder.com/255x380" alt=""/>
                        <div className="team-text text-center">
                            <h4 className="fs-22 f-700 text-custom-pink mb-10">Kenne G. Patten</h4>
                            <p className="white mb-0">Lead Pastor</p>
                            <div className="social-team">
                                <ul className="social-icons white">
                                    <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="team-img shadow-1 js-tilt3">
                        <img src="https://via.placeholder.com/255x380" alt=""/>
                        <div className="team-text text-center">
                            <h4 className="fs-22 f-700 text-custom-pink mb-10">Kim I. Bailey</h4>
                            <p className="white mb-0">Lead Pastor</p>
                            <div className="social-team">
                                <ul className="social-icons white">
                                    <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="team-img shadow-1 js-tilt3">
                        <img src="https://via.placeholder.com/255x380" alt=""/>
                        <div className="team-text text-center">
                            <h4 className="fs-22 f-700 text-custom-pink mb-10">Eliot Rob</h4>
                            <p className="white mb-0">Lead Pastor</p>
                            <div className="social-team">
                                <ul className="social-icons white">
                                    <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- team end -->  */}
    {/* <!-- testimonials start -->  */}
    <section className="pt-80 pb-80 pb-xs-45 bg-light-white">
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">Testimonials</h5>
                    <h3 className="text-dark-red fw-700 fs-41">What Our Member Says</h3>
                </div>
                <div className="section-description">
                    <p className="text-custom-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="slider-sec">
                        <div className="owl-carousel owl-theme testimonial-slide2">
                            <div className="item">
                                <div className="each-quote relative">
                                    <ul className="stars-rate fs-14 mb-5" data-starsactive="5">
                                        <li className="text-md-left text-center"> <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h4 className="fs-22 f-700 mt-10 mb-30">He is the Best</h4>
                                    <p className="mb-25">Quisque enim ipsum, commodo et ven enatis rutrum, luctus in enim. Quisque dapibus lacus non pulvinar lobortis. Cras odio dolor, pulvinar id ligula non, congue aliquam ve.</p>
                                    <div className="client-2-img d-flex align-items-center justify-content-md-start justify-content-center">
                                        <div className="img-div mr-20 pb-0">
                                            <div className="client-image">
                                                <img src="https://via.placeholder.com/53" className=" rounded-circle" alt=""/>
                                            </div>
                                        </div>
                                        <div className="client-text-2 mb-10">
                                            <h6 className="client-name yellow fs-17 f-700">David Joe</h6>
                                            <p className="mb-0 mt-5 fs-13 f-500">CEO, Abc Company</p>
                                        </div>
                                    </div> <span className="quote-icon-2 light-white"><i className="fas fa-quote-right"></i></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="each-quote relative">
                                    <ul className="stars-rate fs-14 mb-5" data-starsactive="5">
                                        <li className="text-md-left text-center"> <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h4 className="fs-22 f-700 mt-10 mb-30">100% Recommended</h4>
                                    <p className="mb-25">Sed lobortis ipsum eu molestie sagittis. Nunc turpis sapien, accumsan sollicitudin suscipit ac, faucibus finibus justo. Nullam a tristique ligula. Ut gravida erat vitae viverra varius.</p>
                                    <div className="client-2-img d-flex align-items-center justify-content-md-start justify-content-center">
                                        <div className="img-div mr-20 pb-0">
                                            <div className="client-image">
                                                <img src="https://via.placeholder.com/53" className=" rounded-circle" alt=""/>
                                            </div>
                                        </div>
                                        <div className="client-text-2 mb-10">
                                            <h6 className="client-name yellow fs-17 f-700">Jessica</h6>
                                            <p className="mb-0 mt-5 fs-13 f-500">CEO, Abc Company</p>
                                        </div>
                                    </div> <span className="quote-icon-2 light-white"><i className="fas fa-quote-right"></i></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="each-quote relative">
                                    <ul className="stars-rate fs-14 mb-5" data-starsactive="5">
                                        <li className="text-md-left text-center"> <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h4 className="fs-22 f-700 mt-10 mb-30">Loved it</h4>
                                    <p className="mb-25">Donec lacinia risus ac ex mollis, sed faucibus magna pellentesque. Praesent volutpat mi ut leo vehicula, iaculis finibus leo fermentum. Curabitur at eros quis nisi.</p>
                                    <div className="client-2-img d-flex align-items-center justify-content-md-start justify-content-center">
                                        <div className="img-div mr-20 pb-0">
                                            <div className="client-image">
                                                <img src="https://via.placeholder.com/53" className=" rounded-circle" alt=""/>
                                            </div>
                                        </div>
                                        <div className="client-text-2 mb-10">
                                            <h6 className="client-name yellow fs-17 f-700">Angelina</h6>
                                            <p className="mb-0 mt-5 fs-13 f-500">CEO, Abc Company</p>
                                        </div>
                                    </div> <span className="quote-icon-2 light-white"><i className="fas fa-quote-right"></i></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="each-quote relative">
                                    <ul className="stars-rate fs-14 mb-5" data-starsactive="5">
                                        <li className="text-md-left text-center"> <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h4 className="fs-22 f-700 mt-10 mb-30">He is the Best</h4>
                                    <p className="mb-25">Quisque enim ipsum, commodo et ven enatis rutrum, luctus in enim. Quisque dapibus lacus non pulvinar lobortis. Cras odio dolor, pulvinar id ligula non, congue aliquam ve.</p>
                                    <div className="client-2-img d-flex align-items-center justify-content-md-start justify-content-center">
                                        <div className="img-div mr-20 pb-0">
                                            <div className="client-image">
                                                <img src="https://via.placeholder.com/53" className=" rounded-circle" alt=""/>
                                            </div>
                                        </div>
                                        <div className="client-text-2 mb-10">
                                            <h6 className="client-name yellow fs-17 f-700">David Joe</h6>
                                            <p className="mb-0 mt-5 fs-13 f-500">CEO, Abc Company</p>
                                        </div>
                                    </div> <span className="quote-icon-2 light-white"><i className="fas fa-quote-right"></i></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className="each-quote relative">
                                    <ul className="stars-rate fs-14 mb-5" data-starsactive="5">
                                        <li className="text-md-left text-center"> <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                        </li>
                                    </ul>
                                    <h4 className="fs-22 f-700 mt-10 mb-30">100% Recommended</h4>
                                    <p className="mb-25">Sed lobortis ipsum eu molestie sagittis. Nunc turpis sapien, accumsan sollicitudin suscipit ac, faucibus finibus justo. Nullam a tristique ligula. Ut gravida erat vitae viverra varius.</p>
                                    <div className="client-2-img d-flex align-items-center justify-content-md-start justify-content-center">
                                        <div className="img-div mr-20 pb-0">
                                            <div className="client-image">
                                                <img src="https://via.placeholder.com/53" className=" rounded-circle" alt=""/>
                                            </div>
                                        </div>
                                        <div className="client-text-2 mb-10">
                                            <h6 className="client-name yellow fs-17 f-700">Jessica</h6>
                                            <p className="mb-0 mt-5 fs-13 f-500">CEO, Abc Company</p>
                                        </div>
                                    </div> <span className="quote-icon-2 light-white"><i className="fas fa-quote-right"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-nav slider-nav">
                            <a href="#" className="quote-nav bg-yellow left left-nav mr-10"> <i className="fas fa-long-arrow-alt-left"></i>
                            </a>
                            <a href="#" className="quote-nav bg-yellow right right-nav"> <i className="fas fa-long-arrow-alt-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="hr-1 bg-black opacity-1 mt-50 mb-50"></div>
                </div>
            </div>
            {/* <!-- client list start -->  */}
            <div className="row align-items-center">
                <div className="col-lg-5">
                    <div className="happyclients text-center mr-55 mr-lg-00 mb-md-45 mb-xs-15">
                        <h3 className="f-700 fs-41 lh-14">Worshiped with <span className="counter yellow">300</span>+ Members</h3>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="clients-logos">
                        <div className="row no-gutters">
                            <div className="col-lg-4 col-sm-6 text-center">
                                <div className="logo-each flex-center">
                                    <img src="https://via.placeholder.com/160x40" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center">
                                <div className="logo-each flex-center">
                                    <img src="https://via.placeholder.com/160x40" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center">
                                <div className="logo-each flex-center">
                                    <img src="https://via.placeholder.com/160x40" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center">
                                <div className="logo-each flex-center">
                                    <img src="https://via.placeholder.com/160x40" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center">
                                <div className="logo-each flex-center">
                                    <img src="https://via.placeholder.com/160x40" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center">
                                <div className="logo-each flex-center"> <a href="our-partner.html" className="btn btn-yellow f-700 btn-md shadow-1">
                                        VIEW ALL<i className="fas fa-chevron-right ml-15 fs-11"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- client list end -->  */}
        </div>
    </section>
    {/* <!-- testimonials end -->  */}
</div>
        );
    }
}