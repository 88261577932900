import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';

const Article = (props) => {
    return (
        <article className="col-lg-4 col-xs-12 col-md-6 post">
            <div className="blog-wrapper">
                <div className="blog-img">
                    <Link to={`/blogPage/${props.slug}`}>
                        <img src={props.img} className="img-fluid full-width" alt="Blog"/>
                    </Link>
                    <div className="post-meta">
                        <div className="post-date">
                            <div className="date text-custom-white">{dateFormat(props.post_date, "dS")} <sup>{dateFormat(props.post_date, "mmm")}</sup> <span className="year bg-yellow text-custom-white">{dateFormat(props.post_date, "yyyy")}</span></div>
                        </div>
                    </div>
                </div>
                <div className="blog-meta">
                    <div className="cat-box">
                        <div className="cats">
                            <a href="#">{props.title_tag}</a>
                        </div>
                    </div>
                    <h2 className="fw-600 mb-xl-20"><Link to={`/blogPage/${props.slug}`} className="text-custom-black">{props.title}</Link></h2>
                    <div className="post-meta-middle">
                        {/* <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-eye mr-1"></i> <a href="#" className="text-dark-white">10</a></span>
                        <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-thumbs-up mr-1"></i> <a href="#" className="text-dark-white">08</a></span> */}
                        <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-comments mr-1"></i> <a href="#" className="text-dark-white">{props.comments}</a></span>
                    </div>
                    <p className="text-custom-black blog-description">{props.short_description}</p>
                    <div className="blog-footer">{/* 
                        <div className="post-author">
                            <span className="text-dark-white fw-600 fs-14">Post by <Link to={`/listings/${props.slug}`} className="text-dark-white">{props.author}</Link></span>
                        </div> */}
                        <Link to={`/blogPage/${props.slug}`} className="text-custom-black fs-14 fw-600 link">Read More</Link>

                    </div>
                </div>
            </div>
        </article>
    );
};

Article.propTypes = {
    title: PropTypes.string.isRequired,
    title_tag: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    //body: PropTypes.number.isRequired,
    short_description: PropTypes.string.isRequired,
    comments: PropTypes.array.isRequired,
    post_date: PropTypes.string.isRequired,
    category: PropTypes.number.isRequired,
    featured: PropTypes.bool.isRequired,
};

export default Article;
