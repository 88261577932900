import React from 'react';
import { Link, Redirect } from 'react-router-dom';

class FAQ extends React.Component {
    render() {
      return (
        <>
        <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
          <div className="container">
            <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                    <h2 className="f-700">FAQ</h2>
                </div>
                <div className="col-md-4 text-center text-md-right">
                    <ul className="pagination-inner">
                        <li><Link to="/" className="text-custom-pink">Home </Link></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
          </div>
        </section>
        <div class="container">
        <div className="FAQ">
          <br></br><h1 style={{textAlign: "center"}}>FAQ</h1><br></br>
          <p style={{whiteSpace: 'pre-wrap'}}><br></br>
            1.	How  long  does  the  pooja  will  take  to  complete?<br></br>
            Ans: It  may  take  min  2  to  max  4  hours  to  complete  pooja  based  on  the  selected  service.<br></br>
            <br></br>
            2.	What  are  the  preparations  to  be  made  to  perform  the  pooja?<br></br>
            Ans: We will send the details through what’s app and SMS notification about the items to be kept ready once the booking is confirmed.<br></br>
            <br></br>
            3.	Can  I  avail  partial  payment?<br></br>
            Ans: Yes, we  accept  advance  payment  to  confirm  the  service  and  balance  can  be  paid  to  the  gurukal  on  completion  of  service.<br></br>
            <br></br>
            4.	Do  the  gurukal  demand  for  extra  money?<br></br>
            Ans: No, our  gurukals  will  not  demand  for  extra  money  and  if  you  wish,  you  can  provide  extra  dakshina.<br></br>
            <br></br>
            5.	Do  I  need  to  buy  all  the  items  for  the  pooja?<br></br>
            Ans: No, Our  gurukal  will  bring  all  the  pooja  materials  except  Deepam  and  other  house  hold  essential  things  for  the  pooja. Flowers  are  optional  and  can  be  confirmed  during  the  time  of  booking.<br></br>
            <br></br>
          </p>
          </div>
        </div>
        </>
      );
    }
  }

export default FAQ;
