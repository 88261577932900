import React from 'react';
import { Link, Redirect } from 'react-router-dom';
class CancelRefundPolicy extends React.Component {
    render() {
      return (
        <>
        <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
          <div className="container">
            <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                    <h2 className="f-700">Cancellations & Refund Policy</h2>
                </div>
                <div className="col-md-4 text-center text-md-right">
                    <ul className="pagination-inner">
                        <li><Link to="/" className="text-custom-pink">Home </Link></li>
                        <li>Cancellations & Refund Policy</li>
                    </ul>
                </div>
            </div>
          </div>
        </section>
        <div class="container">
        <div className="CancelRefundPolicy">
          <br></br><h2>Cancellations & Refund</h2><br></br>
          <p>
            Cancellations can be made 24 hours or more before the Pooja event. There will be no refunds for cancellations made within 24 hours of the event. Refunds will not be provided for Poojas Cancelled on that particular day during that fixed time due to any reason from the devotee’s side. Notice of cancellation can be made from the user login dashboard or via email/call. A cancellation acknowledgement will be sent within 24 hours and fees refunded within 7-10 working days, subject to this Refund Policy.<br></br>
            Saptharishi Vedic Services do not issue refunds once the order is completed. As a patron you are responsible for understanding this refund policy upon purchasing any product or services at our web site.<br></br> 
            <br></br>
            However, we realize that exceptional circumstance can take place with regard to the, <br></br>
            We may process refund on the following reasons,<br></br>
            1.	Non-delivery of the product or service (due to technical issues).<br></br>
            <br></br>
            2.	Few software mistakes or manual difficulties with the product or service occurs sometimes: although all the products and services are thoroughly reviewed before release, unexpected errors may occur. This reason should be submitted to our Support Team for approval.<br></br>
            <br></br>
            3. 	30% of the refund will be provided if the cancellation is made between 24 hours to 48 hours from the event date if the full amount is paid for the service.<br></br>
           </p><br></br>
            <p><h2>Contact Us</h2><br></br>
            Please note that our Support Team is ready to provide you with timely and efficient professional assistance. We will attempt to find the best convenient solution for your query. Give us 8 to 12 hours for our Support Team to get back to you on the problem.
            </p><br></br>
            <p><h2>Payment Policy</h2><br></br>
            Booking is not confirmed until advance or full payment has been received. Payment can be made by Cash, Check, Visa, MasterCard or Net banking.</p><br></br>
        </div>
        </div>
        </>
      );
    }
  }

export default CancelRefundPolicy;
