import React, { Component } from "react";
import { Route, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login';
import Myaccount from './pages/Admin/Myaccount';
import Home from './pages/Home';
import About from './pages/About';
import Events from "./pages/Events";
import EventPost from "./pages/EventsPost";
import Services from './pages/Services';
import ServiceList from './pages/ServiceList';
import ServicesDetails from "./pages/ServiceDetails";
import Blog from './pages/Blog';
import Contacts from './pages/Contacts';
import BlogPost from './pages/blogPost';
import PrivacyReg from './pages/PrivacyReg';
import CancelRefundPolicy from "./pages/CancelRefundPolicy";
import FAQ from "./pages/FAQ";
import Topbar from './components/Topbar';
import Header from './components/Header';
import Footer from './components/Footer';
//import Login from './Login.js'
import Register from './pages/Auth/Register';
import PrivateRoute from './PrivateRoute';
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./Actions/auth";

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Provider store={store}>
        {/* <!-- Header start --> */}
        <Topbar />
        <Header />
        {/* <!-- Header end --> */}
        <Switch>
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute path="/myaccount" component={Myaccount} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/Events" component={Events} />
          <Route exact path='/EventPage/:id' component={EventPost} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/servicesList/:id/:name" component={ServiceList} />
          <Route exact path="/ServicesDetails/:id" component={ServicesDetails}/>
          <Route exact path="/blog" component={Blog} />
          <Route exact path='/blogPage/:id' component={BlogPost} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/PrivacyReg" component={PrivacyReg} />
          <Route exact path="/CancelRefundPolicy" component={CancelRefundPolicy} />
          <Route exact path="/FAQ" component={FAQ} />
        </Switch>
        <Footer />
      </Provider>
    );
  }
}

export default App;
