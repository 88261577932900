import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactReadMoreReadLess from "react-read-more-read-less";

const PackagesCard = (props) => {
    return (
        <div class="col-lg-12 col-md-6 mb-20 mt-10 bg-white">
            <h3 className="mb-10 mt-10">{props.package_name}</h3>
            <div class="shop-list-view">
                {/* align-items-center */}
                <div class="row">
                    <div class="col-lg-6">     
                        <div class="prdt-list-content ml-40 mb-20">             
                            <p className="text-custom-black mb-xl-20" style={{ whiteSpace: 'pre-wrap' }}>{props.procedure_invoked}</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row align-items-center">
                            <div class="col-lg-7">
                                <div class="prdt-list-content">
                                <h3 className="mb-10">Inclusion</h3>
                                <div className="inclution">
                                    <p><input type="checkbox" value="{props.dakshina}" checked disabled /> Dakshina <br></br>
                                    <input type="checkbox" value="{props.all_pooja_materials}" checked disabled /> All Pooja Materials <br></br>
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="prdt-list-rate">
                                    <div class="prdt-price mb-10">
                                        <span class="real-price">₹{props.price}</span>
                                        <span class="per-day">/ Per Event</span>
                                    </div>
                                    + ₹{props.flowers_and_fruits} Flowers and Fruits (Optional)
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-10">
                    <ReactReadMoreReadLess
                        charLimit={props.procedure_invoked}
                        readMoreText={"Read more ▼"}
                        readLessText={"Read less ▲"}
                        >
                        {props.notes}
                    </ReactReadMoreReadLess>
                    </div>
                </div>
            </div>
        </div>
               
    );
};

PackagesCard.propTypes = {
    package_name: PropTypes.string.isRequired,
    services: PropTypes.string.isRequired,
    procedure_invoked: PropTypes.string.isRequired,
    dakshina: PropTypes.bool.isRequired,
    all_pooja_materials: PropTypes.bool.isRequired,
    flowers_and_fruits: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    notes: PropTypes.string.isRequired,
};

export default PackagesCard;
