// ServiceDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PackagesCard from '../components/PackagesCard';


const ServiceDetails = (props) => {
    const [Services, setServices] = useState({});
    const [Packages, setPackages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [date, setDate] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("date");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [location, setLocation] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("location");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [place, setPlace] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("place");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [language, setLanguage] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("language");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [selectedPackage, setSelectedPackage] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("selectedPackage");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [flowers, setFlowers] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("flowers");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [userDetails, setUserDetails] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("user");
      const initialValue = JSON.parse(saved);
      return initialValue || "";
    });
    const [request_from, setRequest_from] = useState(() => {
      // getting stored value
      const saved = localStorage.getItem("request_from");
      const initialValue = saved;
      return initialValue || "";
    });
    useEffect(() => {
      localStorage.setItem("date", JSON.stringify(date));
    }, [date]);

    useEffect(() => {
      localStorage.setItem("location", JSON.stringify(location));
    }, [location]);

    useEffect(() => {
      localStorage.setItem("place", JSON.stringify(place));
    }, [place]);

    useEffect(() => {
      localStorage.setItem("language", JSON.stringify(language));
    }, [language]);

    useEffect(() => {
      localStorage.setItem("selectedPackage", JSON.stringify(selectedPackage));
    }, [selectedPackage]);

    useEffect(() => {
      localStorage.setItem("flowers", JSON.stringify(flowers));
    }, [flowers]);

    useEffect(() => {
      localStorage.setItem("request_from", window.location.pathname);
    }, [request_from]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const slug = props.match.params.id;

        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        axios.get(`${process.env.REACT_APP_API_URL}/api/Services/${slug}`, config)
        .then(res => {
            setServices(res.data);
        })
        .catch(err => {

        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        const slug = props.match.params.id;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/Services/Packages/list/${slug}`, config);
                console.log(res);
                setPackages(res.data.results);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);
    let [package_selected, setpackage_selected] = useState('');
    const handleChange = (event) => {
       alert(event.target.getAttribute("data-price"));
      setpackage_selected(event.target.getAttribute("data-price"));
    }
    const displayPackage = () => {
        let display = [];
        let result = [];

        Packages.map(Package => {
            return display.push(
                <PackagesCard
                    id={Package.id}
                    package_name={Package.package_name}
                    services={Package.services}
                    procedure_invoked={Package.procedure_invoked}
                    dakshina={Package.dakshina}
                    all_pooja_materials={Package.all_pooja_materials}
                    flowers_and_fruits={Package.flowers_and_fruits}
                    price={Package.price}
                    notes={Package.notes}
                    flowers_and_fruits={Package.flowers_and_fruits}
                />
            );
        });
        for (let i = 0; i < PackagesCard.length; i += 1) {
            result.push(
                <div key={i} className="row">
                    {display[i]}
                    {display[i+1] ? display[i+1] : null}
                    {display[i+2] ? display[i+2] : null}
                </div>
                );
            }

        return result;
    };
    let selectpackages = Packages.map((item, i) => {
      return (
        <option key={i} value={item.price}>
          {item.package_name}
        </option>
      );
    });
    const authLinks = (
      <button type="submit" className="btn btn-block btn-yellow mt-15">ADD TO CART</button>
    );

    const guestLinks = (
      <Link to="/login" className="btn btn-block btn-yellow mt-15">ADD TO CART</Link>
    );
    
    return (
        
<div>
   <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
      <div className="container">
         <div className="row align-items-center">
               <div className="col-md-8 text-center text-md-left">
                  <h2 className="f-700">Service Details</h2>
               </div>
               <div className="col-md-4 text-center text-md-right">
                  <ul className="pagination-inner">
                     <li><Link to="/" className="text-custom-pink">Home </Link></li>
                     <li><Link to="/services" className="text-custom-pink">Services </Link></li>
                     <li>Service Details</li>
                  </ul>
               </div>
         </div>
      </div>
   </section>
   <div className="container mt-40 mb-40">
      <div className="row mb-20">
         <div className="col-md-3">
            <img src={Services.service_image} alt="" />
         </div>
         <div className="col-md-9">
            <h3 className="mb-10">Description</h3>
            <p align="justify" style={{ whiteSpace: 'pre-wrap' }}>{Services.long_description}</p>
            <div className="row">
               <div className="col-md-6">
                  <h3 className="mb-10">Features</h3>
                  <div className="mb-20" style={{ whiteSpace: 'pre-wrap' }}>
                     {Services.key_insight}
                  </div>
               </div>
               <div className="col-md-6">
                  <h3 className="mb-10">We Assure</h3>
                  <div className="ma-20" style={{ whiteSpace: 'pre-wrap' }}>
                     {Services.our_promises}
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* Packages Details Start*/}
      <hr>
      </hr>
      <div className="container">
         <div class="packages-container">
            {/*packeges Starts*/}
            <section>
               <div className="standard-style">
                  <div className="container" >
                  <br></br>
                  {(Services.category == 1) ? <p style={{ color: "red", textAlign: "justify" }} >*Disclaimer: SAPTHARISHI VEDIC SERVICES is not the official representative of the temple, this is not the official website of the temple, Saptharishi Vedic Services has a network of representatives who visit the temple & get the poojai done in your name, our poojai cost includes, temple's official fees, poojai samagri items cost, priests Dakshina, Prasadam shipping cost across India & abroad and Saptharishi Vedic Services service charges</p>:''}
                     <div className="row">
                        <div className="col-md-8 pl-0">
                        {(Services.category == 1) ?'':<h2 style={{textAlign:"center"}}>Select Your Package</h2>}
                           {displayPackage()}
                        </div>
                        <div className="col-md-4">
                        <h2 style={{textAlign:"center"}}>Order Details</h2>
                        <br></br>
                        <form action="#">
                           <div className="row">
                           <div className="col-lg-12">
                           <div className="form-group">
                                 <label className="control-label">Choose a Date</label>
                                 <input type="date" class="form-control bg-white input-lg input-white shadow-5" value={date} onChange={(e) => setDate(e.target.value)} name="event_date" placeholder="Choose a Date" />
                              </div>
                           </div>
                           {(() => {

                                    if (Services.category == 1) {

                                    return (

                                       <div>

                                       </div>
                                    )
                                    } 
                                     else {

                                    return (
                                       <div className="container">
                                       <div className="row">
                                       <div className="col-lg-12">
                                       <div className="form-group">
                                          <label className="control-label">Choose Location</label>
                                          <select name="location" class="form-control bg-white input-lg input-white shadow-5" value={location} onChange={(e) => setLocation(e.target.value)}>
                                             <option value="">Select Location</option>
                                             <option value="Chennai">Chennai</option>
                                             <option value="Tanjore">Tanjore</option>
                                             <option value="Trichy">Trichy</option>
                                             <option value="karaikal">karaikal</option>
                                             <option value="Coimbatore">Coimbatore</option>
                                             <option value="Thiruvarur">Thiruvarur</option>
                                             <option value="Madurai">Madurai</option>
                                             <option value="Tiruchirappalli">Tiruchirappalli</option>
                                             <option value="Tiruppur">Tiruppur</option>
                                             <option value="Salem">Salem</option>
                                             <option value="Erode">Erode</option>
                                             <option value="Vellore">Vellore</option>
                                             <option value="Tirunelveli">Tirunelveli</option>
                                             <option value="Thoothukudi">Thoothukudi</option>
                                          </select>
                                       </div>
                                    </div>
                                    <div className="col-lg-12">
                                    <div className="form-group">
                                          <label className="control-label">Choose Place</label>
                                          <select name="place" class="form-control bg-white input-lg input-white shadow-5" value={place} onChange={(e) => setPlace(e.target.value)}>
                                             <option value="" selected disabled>Select Place</option>
                                             <option value="Home">Home</option>
                                             <option value="Office">Office</option>
                                             <option value="Online">Online</option>
                                          </select>
                                       </div>            
                                    </div>
                                    </div>
                                    </div>
                                    )
                                    }
                                    })()}
                           
                           <div className="col-lg-12">
                           <div className="form-group">
                                 <label className="control-label">Prefered Language</label>
                                 <select name="language" class="form-control bg-white input-lg input-white shadow-5" value={language} onChange={(e) => setLanguage(e.target.value)}>
                                    <option value="" selected disabled>Select Language</option>
                                    <option value="Tamil">Tamil</option>
                                    <option value="Telugu">Telugu</option>
                                    <option value="Kanada">Kanada</option>
                                    <option value="English">English</option>
                                 </select>
                              </div>            
                           </div>
                           <div className="col-lg-12">
                              <div className="form-group">
                                 <label className="control-label">Select Package</label>
                                 <select name="place" class="form-control bg-white input-lg input-white shadow-5" value={selectedPackage} onChange={(e) => setSelectedPackage(e.target.value)}>
                                    <option value="" selected disabled>Select Package</option>
                                    {selectpackages}
                                 </select>
                              </div>
                           </div>
                           <div className="col-md-12">
                              <div className="form-group">
                                 <label className="control-label">Include Fruits & Flowers</label>
                                 <select name="place" class="form-control bg-white input-lg input-white shadow-5" value={flowers} onChange={(e) => setFlowers(e.target.value)}>
                                    <option value="Yes">Yes</option>
                                    <option value="No" selected>No</option>
                                 </select>
                              </div>
                           </div> 
                           <div className="col-lg-12">
                           {userDetails ? authLinks : guestLinks}
                           </div>
                        </div>
                        </form>   
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {/*packeges end*/}  
         </div>
      </div>
      
   </div>
</div>


    );
};

export default ServiceDetails;
