import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';

const EventCard = (props) => {
    return (
        <article className="col-lg-4 col-md-6 post">
                    <div className="blog-wrapper">
                        <div className="blog-img">
                            <Link to={`/eventPage/${props.id}`}>
                                <img src={props.images} className="img-fluid full-width" height="300" alt="Blog"/>
                            </Link>
                            <div className="post-meta">
                                <div className="post-date">
                                    <div className="date text-custom-white">{dateFormat(props.day, "dS")} <sup>{dateFormat(props.day, "mmm")}</sup> <span className="year bg-yellow text-custom-white">{dateFormat(props.day, "yyyy")}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="blog-meta">
                            <div className="cat-box">
                                <div className="cats">
                                    <a href="#">{props.title_tag}</a>
                                </div>
                            </div>
                            <h2 className="fw-600 mb-xl-20"><Link to={`/eventPage/${props.id}`} className="text-custom-black">{props.title}</Link></h2>
                            <p className="text-custom-black blog-description">{props.short_description}</p>
                            <div className="blog-footer">{/* 
                                <div className="post-author">
                                    <span className="text-dark-white fw-600 fs-14">Post by <Link to={`/listings/${props.id}`} className="text-dark-white">{props.author}</Link></span>
                                </div> 
                                <Link to={`/eventPage/${props.id}`} className="text-custom-black fs-14 fw-600 link">Read More</Link>*/}

                            </div>
                        </div>
                    </div>
        </article>
    );
};

EventCard.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    title_tag: PropTypes.string.isRequired,
    short_description: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    images: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
};

export default EventCard;
