// blogPost.js
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

const EventPost = (props) => {
    const [events, setEvents] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        const slug = props.match.params.id;

        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };

        axios.get(`${process.env.REACT_APP_API_URL}/api/event/${slug}`, config)
        .then(res => {
            console.log(res);
            setEvents(res.data);
        })
        .catch(err => {

        });
    }, [props.match.params.category]);

    const displayInteriorImages = () => {
        let images = [];

        images.push(
            <div key={1}>
                {
                    events.img ? (
                        <div className="row mb-35">
                            <div className="col-md-6 offset-md-3 mb-sm-15">
                                <img className='img-100 img-fluid' src={events.img} alt='Event' />
                            </div>
                        </div>
                    ) : null
                }
            </div>
        );

        return images;
    };

    return (
        <div>
            <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 text-center text-md-left">
                            <h2 className="f-700">Event Details</h2>
                        </div>
                        <div className="col-md-4 text-center text-md-right">
                            <ul className="pagination-inner">
                                <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                <li><Link to="/events" className="text-custom-pink">Events </Link></li>
                                <li>Event Details</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        {/* <!-- Event head start --> */}
        <section className="events-header">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="event-head-top relative">
                            <h2 className="mt-20 f-700 lh-12">{events.title}</h2>
                            <div className="nav-event-detail mt-md-45">
                                {/* <a href={prev} className="left fs-13 f-700 transform-v-center"> <i className="fas fa-long-arrow-alt-left mr-20"></i>PREV</a> <a href={next} className="right fs-13 f-700 transform-v-center">
                            NEXT<i className="fas fa-long-arrow-alt-right ml-20"></i>
                        </a> */}
                            </div>
                        </div>
                        <div className="event-by-info pt-20 pb-20 mb-55 mt-55">
                            <ul className="list-inline fs-13 text-left text-sm-center">
                                <li className="list-inline-item"> <i className="far fa-user mr-10 fs-13 text-custom-pink"></i> Admin</li>
                                <li className="list-inline-item"> <i className="far fa-calendar mr-10 fs-13 text-custom-pink"></i> {dateFormat(events.post_date, "mmmm dS, yyyy")}</li>
                                <li className="list-inline-item"> <i className="far fa-comments mr-10 fs-13 text-custom-pink"></i> {events.comments} Comments</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Event head end --> */}
        {/* <!-- Event details start --> */}
    <section className="event-details pb-80">
        <div className="container">
            <div className="row ">
                <div className="col-lg-8">
                    {/* <!-- left event start --> */}
                    <article className="event-detail-left">
                        <p align="justify">{events.short_description}</p>
                        {displayInteriorImages()}
                        <p className="block-quote-text z-5 mb-30" align="justify">{events.body}</p>
                        {/* <div className="block-quote-2 mb-30 mt-20"> <span className="quote-icon2 yellow">“</span>
                            <div className="block-quote-text z-5">
                                <p className="blue fs-23 lh-15 rbt-slab">Aenean vestibulum sapien ut felis consequat tempus. Quisque condimentum ipsum a condimentum ornare Suspendisse potentis</p> <span className="block-quote-by fs-13 f-400 uppercase">Admin</span>
                            </div>
                        </div> */}
                        {/* <h3 className="f-700 mb-10">Condimentum Lornare</h3>
                        <p>Vestibulum ac porttitor dolor, ac feugiat nunc. Nulla eleifend massa tortor, nec laoreet lectus varius ac. Fusce scelerisque consequat eros, non venenatis turpis ultricies id. Etiam nec sagittis diam, a tempor risus. Nunc nisl
                            felis, congue non euismod ac, dignissim id eros.</p> */}
                        <div className="tag-social pt-20 pt-sm-15 pb-20 mt-30">
                            <div className="row align-items-center">
                                <div className="col-xl-7">
                                    <div className="tag-list d-flex align-items-center"> <span className="f-700 mr-15">Tags:</span>
                                        <ul>
                                            <li><a href="#">#God</a>
                                            </li>
                                            <li><a href="#">#Faith</a>
                                            </li>
                                            <li><a href="#">#Prayer</a>
                                            </li>
                                            <li><a href="#">#Temple</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-5">
                                    <div className="social-shate mt-lg-15 d-flex align-items-center justify-content-start justify-content-xl-end"> <span className="f-700 mr-15">Share:</span>
                                        <ul className="social-icons footer-social social-md">
                                            <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                            </li>
                                            <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                            </li>
                                            <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                            </li>
                                            <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="related-events our-articles mb-md-80">
                            <div className="section-header">
                                <div className="section-heading">
                                    <h5 className="text-custom-pink">Our Event</h5>
                                    <h3 className="text-dark-red fw-700 fs-41">Related Articles</h3>
                                </div>
                                <div className="section-description">
                                    <p className="text-custom-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slider-sec">
                                        <div className="owl-carousel owl-theme relative-slider">
                                            <div className="item">
                                                <article className="post">
                                                    <div className="event-wrapper">
                                                        <div className="event-img">
                                                            <a href="event-detail.html">
                                                                <img src="https://via.placeholder.com/350x200" className="img-fluid full-width" alt="Event"/>
                                                            </a>
                                                            <div className="post-meta">
                                                                <div className="post-date">
                                                                    <div className="date text-custom-white">05 <sup>DEC</sup> <span className="year bg-yellow text-custom-white">2019</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-meta">
                                                            <div className="cat-box">
                                                                <div className="cats">
                                                                    <a href="#">Temple</a>
                                                                    <a href="#">Aarti</a>
                                                                </div>
                                                            </div>
                                                            <h2 className="fw-600 mb-xl-20"><a href="event-detail.html" className="text-custom-black">The PC has improved the world.</a></h2>
                                                            <div className="post-meta-middle">
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-eye mr-1"></i> <a href="#" className="text-dark-white">10</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-thumbs-up mr-1"></i> <a href="#" className="text-dark-white">08</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-comments mr-1"></i> <a href="#" className="text-dark-white">02</a></span>
                                                            </div>
                                                            <p className="text-custom-black event-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                                            <div className="event-footer">
                                                                <div className="post-author">
                                                                    <span className="text-dark-white fw-600 fs-14">Post by <a href="Event-detail.html" className="text-dark-white">Brian Wright</a></span>
                                                                </div>
                                                                <a href="event-detail.html" className="text-custom-black fs-14 fw-600 link">Read More</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                            </div>
                                                            <div className="item">
                                                                <article className="post">
                                                    <div className="event-wrapper">
                                                        <div className="event-img">
                                                            <a href="event-detail.html">
                                                                <img src="https://via.placeholder.com/350x200" className="img-fluid full-width" alt="Event"/>
                                                            </a>
                                                            <div className="post-meta">
                                                                <div className="post-date">
                                                                    <div className="date text-custom-white">05 <sup>DEC</sup> <span className="year bg-yellow text-custom-white">2019</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-meta">
                                                            <div className="cat-box">
                                                                <div className="cats">
                                                                    <a href="#">Temple</a>
                                                                    <a href="#">Aarti</a>
                                                                </div>
                                                            </div>
                                                            <h2 className="fw-600 mb-xl-20"><a href="event-detail.html" className="text-custom-black">The PC has improved the world.</a></h2>
                                                            <div className="post-meta-middle">
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-eye mr-1"></i> <a href="#" className="text-dark-white">10</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-thumbs-up mr-1"></i> <a href="#" className="text-dark-white">08</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-comments mr-1"></i> <a href="#" className="text-dark-white">02</a></span>
                                                            </div>
                                                            <p className="text-custom-black event-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                                            <div className="event-footer">
                                                                <div className="event-author">
                                                                    <span className="text-dark-white fw-600 fs-14">Post by <a href="event-detail.html" className="text-dark-white">Brian Wright</a></span>
                                                                </div>
                                                                <a href="event-detail.html" className="text-custom-black fs-14 fw-600 link">Read More</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                            </div>
                                                            <div className="item">
                                                                <article className="post">
                                                    <div className="event-wrapper">
                                                        <div className="event-img">
                                                            <a href="event-detail.html">
                                                                <img src="https://via.placeholder.com/350x200" className="img-fluid full-width" alt="Event"/>
                                                            </a>
                                                            <div className="post-meta">
                                                                <div className="post-date">
                                                                    <div className="date text-custom-white">05 <sup>DEC</sup> <span className="year bg-yellow text-custom-white">2019</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-meta">
                                                            <div className="cat-box">
                                                                <div className="cats">
                                                                    <a href="#">Temple</a>
                                                                    <a href="#">Aarti</a>
                                                                </div>
                                                            </div>
                                                            <h2 className="fw-600 mb-xl-20"><a href="event-detail.html" className="text-custom-black">The PC has improved the world.</a></h2>
                                                            <div className="post-meta-middle">
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-eye mr-1"></i> <a href="#" className="text-dark-white">10</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-thumbs-up mr-1"></i> <a href="#" className="text-dark-white">08</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-comments mr-1"></i> <a href="#" className="text-dark-white">02</a></span>
                                                            </div>
                                                            <p className="text-custom-black event-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                                            <div className="event-footer">
                                                                <div className="post-author">
                                                                    <span className="text-dark-white fw-600 fs-14">Post by <a href="event-detail.html" className="text-dark-white">Brian Wright</a></span>
                                                                </div>
                                                                <a href="event-detail.html" className="text-custom-black fs-14 fw-600 link">Read More</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                            </div>
                                                            <div className="item">
                                                                <article className="post">
                                                    <div className="event-wrapper">
                                                        <div className="event-img">
                                                            <a href="event-detail.html">
                                                                <img src="https://via.placeholder.com/350x200" className="img-fluid full-width" alt="Event"/>
                                                            </a>
                                                            <div className="post-meta">
                                                                <div className="post-date">
                                                                    <div className="date text-custom-white">05 <sup>DEC</sup> <span className="year bg-yellow text-custom-white">2019</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-meta">
                                                            <div className="cat-box">
                                                                <div className="cats">
                                                                    <a href="#">Temple</a>
                                                                    <a href="#">Aarti</a>
                                                                </div>
                                                            </div>
                                                            <h2 className="fw-600 mb-xl-20"><a href="event-detail.html" className="text-custom-black">The PC has improved the world.</a></h2>
                                                            <div className="post-meta-middle">
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-eye mr-1"></i> <a href="#" className="text-dark-white">10</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-thumbs-up mr-1"></i> <a href="#" className="text-dark-white">08</a></span>
                                                                <span className="text-custom-pink fs-14 mr-4"><i className="fas fa-comments mr-1"></i> <a href="#" className="text-dark-white">02</a></span>
                                                            </div>
                                                            <p className="text-custom-black event-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                                                            <div className="event-footer">
                                                                <div className="post-author">
                                                                    <span className="text-dark-white fw-600 fs-14">Post by <a href="event-detail.html" className="text-dark-white">Brian Wright</a></span>
                                                                </div>
                                                                <a href="event-detail.html" className="text-custom-black fs-14 fw-600 link">Read More</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="event-nav slider-nav">
                                            <a href="#" className="relative-nav-left bg-yellow left-nav"> <i class='fas fa-long-arrow-alt-left'></i>
                                            </a>
                                            <a href="#" className="relative-nav-right bg-yellow right-nav"> <i class='fas fa-long-arrow-alt-right'></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                {/* <!-- left event end --> */}
                <aside className="col-lg-4">
                    {/* <!-- sidebar start --> */}
                    <div className="sidebar-outer bg-light-white">
                        <aside className="side-box">
                            <form action="#" className="relative mt-10 mb-10">
                                <input type="text" className="form-control input-white search-white shadow-5" id="search2" placeholder="Search here.."/> <i className="fas fa-search transform-v-center transition-4 green"></i>
                            </form>
                        </aside>
                        <aside className="side-box ">
                            <h4 className="f-700 mb-20">Popular Posts</h4>
                            <div className="popular-posts mb-10">
                                <a href="/eventPost/1" className="popular-post d-flex align-items-center">
                                    <div className="popular-post-img mr-20">
                                        <img src="https://via.placeholder.com/82" alt=""/>
                                        <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-external-link-alt transform-center"></i>
                                        </div>
                                    </div>
                                    <div className="popular-post-text">
                                        <h5 className="mb-5 fs-15 rbt-slab lh-15">Rina vimocharar temple, Thirucherai, Kumbakonam</h5> <span className="fs-12 f-700 text-custom-pink">June 30, 2021</span>
                                    </div>
                                </a>
                                <a href="/EventPost/2" className="popular-post d-flex align-items-center">
                                    <div className="popular-post-img mr-20">
                                        <img src="https://via.placeholder.com/82" alt=""/>
                                        <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-external-link-alt transform-center"></i>
                                        </div>
                                    </div>
                                    <div className="popular-post-text">
                                        <h5 className="mb-5 fs-15 rbt-slab lh-15">Lakshmi Kubera Pooja</h5> <span className="fs-12 f-700 text-custom-pink">June 30, 2021</span>
                                    </div>
                                </a>
                                <a href="/eventPost/3" className="popular-post d-flex align-items-center">
                                    <div className="popular-post-img mr-20">
                                        <img src="https://via.placeholder.com/82" alt=""/>
                                        <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-external-link-alt transform-center"></i>
                                        </div>
                                    </div>
                                    <div className="popular-post-text">
                                        <h5 className="mb-5 fs-15 rbt-slab lh-15">Rudra Pooja</h5> <span className="fs-12 f-700 text-custom-pink">June 30, 2021</span>
                                    </div>
                                </a>
                            </div>
                        </aside>
                        <aside className="side-box">
                            <h4 className="f-700 mb-20">Tags</h4>
                            <div className="tags-widget"> <a href="#" className="tag-link">God</a>
                                <a href="#" className="tag-link">Faith</a>
                                <a href="#" className="tag-link">Prayer</a>
                                <a href="#" className="tag-link">Temple</a>
                                <a href="#" className="tag-link">Sermon</a>
                            </div>
                        </aside>
                        <aside className="side-box">
                            <h4 className="f-700 mb-20">Instagram</h4>
                            <div className="row gallery-row">
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-4 gallery-clmn">
                                    <div className="each-gallery">
                                        <a className="gallery-links" href="https://via.placeholder.com/90" title="Gallery description here">
                                            <img src="https://via.placeholder.com/90" alt=""/>
                                            <div className="full-cover bg-yellow-op-8 transition-4"> <i className="fas fa-expand-arrows-alt transform-center"></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <aside className="side-box mb-10">
                            <h4 className="f-700 mb-20">Follow Us</h4>
                            <ul className="social-icons footer-social">
                                <li> <a href="#"><i className="fab fa-facebook-f"></i></a>
                                </li>
                                <li> <a href="#"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li> <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                </li>
                                <li> <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                </li>
                            </ul>
                        </aside>
                    </div>
                </aside>
                {/* <!-- sidebar end --> */}
            </div>
        </div>
    </section>
    {/* <!-- event details end --> */}
            
        </div>
    );
};

export default EventPost;
