import { combineReducers } from 'redux';
import AuthReducer from './auth';
import alert from './alert';

const AllReducers = combineReducers({
    alert,
    AuthReducer,
});

export default AllReducers;
