// Services.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServiceListCard from '../components/ServiceListCard';
import Pagination from '../components/Pagination';
import { Link } from 'react-router-dom';

const ServiceList  = (props) => {
    const [categories, setCategories] = useState([]);
    const [count, setCount] = useState(0);
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    const [active, setActive] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        const slug = props.match.params.id;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        };
        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/Services/Category/listing/${slug}?page=1`, config);
                setCategories(res.data.results);
                setCount(res.data.count);
                setPrevious(res.data.previous);
                setNext(res.data.next);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayCategory = () => {
        let display = [];
        let result = [];

        categories.map(category => {
            return display.push(
                <ServiceListCard
                    id={category.id}
                    service_name={category.service_name}
                    short_description={category.short_description}
                    long_description={category.long_description}
                    price_range={category.price_range}
                    key_insight={category.key_insight}
                    our_promises={category.our_promises}
                    service_image={category.service_image}
                    category={category.category}
                    category_name={props.match.params.name}
                />
            );
        });

        for (let i = 0; i < categories.length; i += 3) {
            result.push(
                <div key={i} className="row">
                        {display[i]}
                        {display[i+1] ? display[i+1] : null}
                        {display[i+2] ? display[i+2] : null}
                </div>
            );
        }

        return result;
    };
    
    const visitPage = (page) => {
        const slug = props.match.params.id;
        axios.get(`${process.env.REACT_APP_API_URL}/api/Services/Category/listing/${slug}?page=${page}`)
        .then(res => {
            setCategories(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            setActive(page);
        })
        .catch(err => {

        });
    };

    const previous_number = () => {
        axios.get(previous)
        .then(res => {
            setCategories(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            if (previous)
                setActive(active-1);
        })
        .catch(err => {

        });
    };

    const next_number = () => {
        axios.get(next)
        .then(res => {
            setCategories(res.data.results);
            setPrevious(res.data.previous);
            setNext(res.data.next);
            if (next)
                setActive(active+1);
        })
        .catch(err => {

        });
    };


        return (
            <div>
                <section className="shop-bnr bnr-pagination pt-35 pt-sm-20 pb-45 pb-sm-20 overlay-bg-black">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8 text-center text-md-left">
                                <h2 className="f-700">Service Available</h2>
                            </div>
                            <div className="col-md-4 text-center text-md-right">
                                <ul className="pagination-inner">
                                    <li><Link to="/" className="text-custom-pink">Home </Link></li>
                                    <li><Link to="/services" className="text-custom-pink">Services </Link></li>
                                    <li>Service Available</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
    <section class="shop-1-list shop-list pt-80 pb-80"> 
        <div class="container">
        {displayCategory()}
        <div className="row">
            <div className="col-12 text-center">
                <div className="pagination-sml pt-10 text-center">
                    <Pagination
                        itemsPerPage={9}
                        count={count}
                        visitPage={visitPage}
                        previous={previous_number}
                        next={next_number}
                        active={active}
                        setActive={setActive}
                    />
                </div>
            </div>
        </div>
        </div>
    </section>
    </div>
        );
    }
export default ServiceList;