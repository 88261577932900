// Home.js
import React, {Component,useState, useEffect} from 'react';
import OwlCarousel from 'react-owl-carousel';  
import axios from 'axios';
import Weeklyblogcard from '../components/Weeklyblogcard';
import ServiceCard from '../components/ServiceCard';

const Home = () => {
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/Services/Category/list`);
                setCategories(res.data.results);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayCategory = () => {
        let display = [];
        let result = [];

        categories.map(category => {
            return display.push(
                <ServiceCard
                    id={category.id}
                    categoryname={category.categoryname}
                    description={category.description}
                    image={category.image}
                />
            );
        });

        for (let i = 0; i < categories.length; i += 3) {
            result.push(
                <div key={i} className="row">
                        {display[i]}
                        {display[i+1] ? display[i+1] : null}
                        {display[i+2] ? display[i+2] : null}
                </div>
            );
        }

        return result;
    };
   
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/featured`);
                console.log(res.data.results)
                setPosts(res.data.results);
            }
            catch (err) {

            }
        }

        fetchData();
    }, []);

    const displayPosts = () => {
        let display = [];
        let result = [];

        posts.map(post => {
            return display.push(
                <Weeklyblogcard
                    title={post.title}
                    title_tag={post.title_tag}
                    slug={post.slug}
                    img={post.img}
                    short_description={post.short_description}
                    comments={post.comments}
                    post_date={post.post_date}
                    category={post.category}
                    featured={post.featured}
                />
            );
        });

        for (let i = 0; i < posts.length; i += 3) {
            result.push(
                <OwlCarousel key={i} items={3} className="owl-theme" loop  
                                loop={true}
                                margin={30}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                autoplayTimeout={4000}
                                >
                        <div className="item">
                        {display[i]}
                        </div>
                        <div className="item">
                        {display[i+1] ? display[i+1] : null}
                        </div>
                        <div className="item">
                        {display[i+2] ? display[i+2] : null}
                        </div>
                </OwlCarousel>
            );
        }

        return result;
    };
    return (
        <div>
    
    <section className="slider-2 relative">
    <OwlCarousel items={1} className="owl-theme" loop  
        interval={100}
          nav 
          dots
          autoplay
          autoplayTimeout={5000}
          smartSpeed={1000}
          autoplayHoverPause

          margin={8}  >
        <div>
            <div className="item">
                <div className="each-slider-2 flex-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Banner/banner-h3-1.jpg"})` }} data-overlay="4">\
                </div>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="banner-content-3 white z-10"> <span className="frame-top"></span>
                                    <span className="frame-bottom"></span>
                                    <div className="main-banner-texts">
                                        <h1 className="f-700">Saptharishi <span className="text-custom-pink">Vedic</span> <span className="blk-spn">Services </span></h1>
                                        <p className="fs-15">Saptharishi Vedic Services is all about serving the people with a manifesto of various Hindu religious services to invoke the blessings of God. We provide Online & Offline Homam, Pooja and exclusive Temple services by executing in a splendid way.</p> <a href="/services" className="btn btn-border-out z-5">View All Services</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div className="item">
                <div className="each-slider-2 flex-center" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Banner/banner-h1-b1.jpg"})` }} data-overlay="4">\
                </div>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="banner-content-3 white z-10"> <span className="frame-top"></span>
                                    <span className="frame-bottom"></span>
                                    <div className="main-banner-texts">
                                    <h1 className="f-700">Saptharishi <span className="text-custom-pink">Vedic</span> <span className="blk-spn">Services </span></h1>
                                        <p className="fs-15">Saptharishi Vedic Services is all about serving the people with a manifesto of various Hindu religious services to invoke the blessings of God. We provide Online & Offline Homam, Pooja and exclusive Temple services by executing in a splendid way.</p> <a href="/services" className="btn btn-border-out z-5">View All Services</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </OwlCarousel>
    </section>
    <section className="pt-80 pb-80 about-temple">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="images-grids row justify-content-center clearfix mb-xl-80">
                        <div className="col-lg col-2">
                            <div className="image-boxs mb-md-30">
                                <img src="assets/images/icon5.png" className="image-fit" alt="img"/>
                            </div>
                        </div>
                        <div className="col-lg col-2">
                            <div className="image-boxs mb-md-30">
                                <img src="assets/images/icon1.png" className="image-fit" alt="img"/>
                            </div>
                        </div>
                        <div className="col-lg col-2">
                            <div className="image-boxs mb-md-30">
                                <img src="assets/images/icon2.png" className="image-fit" alt="img"/>
                            </div>
                        </div>
                        <div className="col-lg col-2">
                            <div className="image-boxs mb-sm-30">
                                <img src="assets/images/icon4.png" className="image-fit" alt="img"/>
                            </div>
                        </div>
                        <div className="col-lg col-2">
                            <div className="image-boxs">
                                <img src="assets/images/icon5.png" className="image-fit" alt="img"/>
                            </div>
                        </div>
                    </div>
                    <div className="row testimonials-area">
                        <div className="col-lg-12 text-center">
                            <h3 className="f-700 fs-41 mb-30">Our History</h3>
                            <span className="icon-bg-quote mt-15 transform-center opacity-5"><img src="assets/images/icon.png" alt=""/></span>
                        </div>
                        <div className="col-lg-10 offset-lg-1 text-center">
                        <OwlCarousel items={1} 
                        loop={true} margin={0} nav={true} dots={true} autoplay={true}
                        navText={["<i class='fas fa-arrow-alt-circle-left mr-5'></i>", "<i class='fas fa-arrow-alt-circle-right ml-5'></i>"]}
                        
                        autoplayTimeout={6000} >
                                <div className="item">
                                    <p>We are the huge number of Gurukals/Pandits work in a principle to enrich the spiritual culture with the knowledge gained from Vedic Pathashala. We are connected with 500+ temples in India & USA. We save our tradition & practices that are followed for decades.</p>
                                    <div className="quote-by">
                                        <h5 className="yellow f-700 fs-16 mt-30">2021</h5>
                                        <p className="mt-5">Established</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <p>As a team we have organized for 108 Shiva Temple “pradosham” abhisheygam by extending our support to the gurukals across South Tamilnadu. We have conducted many spiritual rituals in association with many temples for the welfare of our gurukals.</p>
                                    <div className="quote-by">
                                        <h5 className="yellow f-700 fs-16 mt-30">2021</h5>
                                        <p className="mt-5">Established</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <p>Our parent organization Andharmugam Foundation has served many persons during the pandemic situation of Covid 19. We also help the poor children for their education, health and upgrading their skills. </p>
                                    <div className="quote-by">
                                        <h5 className="yellow f-700 fs-16 mt-30">2021</h5>
                                        <p className="mt-5">Established</p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Start temple-counter --> */}
    <section className="pt-80 temple-counter parallax">
        <div className="overlay overlay-bg-black"></div>
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">Our Family</h5>
                    <h3 className="text-white fw-700 fs-41">We Work Together</h3>
                </div>
                <div className="section-description">
                    <p className="text-white">We ensure the pooja is performed in the fixed time with appropriate vedic mantras and high quality samagri’s. Our numbers describe more about us,</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 col-sm-6">
                    <div className="counter-box mb-md-40">
                        <div className="counter-icon">
                            <i className="flaticon-charity"></i>
                        </div>
                        <div className="count">
                            <span className="counter text-custom-white">457</span>
                            <span className="fs-16 text-custom-white">Homams</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="counter-box mb-md-40">
                        <div className="counter-icon">
                            <i className="flaticon-gurukal"></i>
                        </div>
                        <div className="count">
                            <span className="counter text-custom-white">2000</span>
                            <span className="fs-16 text-custom-white">Gurukals</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="counter-box mb-xs-40">
                        <div className="counter-icon">
                            <i className="flaticon-community"></i>
                        </div>
                        <div className="count">
                            <span className="counter text-custom-white">228</span>
                            <span className="fs-16 text-custom-white">Events</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                    <div className="counter-box">
                        <div className="counter-icon">
                            <i className="flaticon-temple"></i>
                        </div>
                        <div className="count">
                            <span className="counter text-custom-white">179</span>
                            <span className="fs-16 text-custom-white">Yatra’s/Temple Services</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End temple-counter --> */}
    {/* <!-- Start Temple Video --> */}
    <div className="temple-video-sec pb-80">
        <div className="container">
            <div className="col-lg-10 offset-lg-1">
                <div className="video-box">
                    <div className="video_wrapper video_wrapper_full js-videoWrapper">
                        <iframe className="videoIframe js-videoIframe" src="https://www.youtube.com/embed/m_BpoFgL8mg" data-src="https://www.youtube.com/embed/m_BpoFgL8mg?autoplay=1" allow="autoplay"></iframe>
                        <div className="videoPoster js-videoPoster">
                            <img src="/assets/images/0045.jpg" className="image-fit" alt="#"/>
                            <div className="video-inner video-btn-wrapper"> <a href="JavaScript:Void(0);" className=""><i className="far fa-play-circle"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- End Temple Video --> */}
    {/* <!-- Start samadhi-center --> */}
    <section className="pt-80 pb-80 samadhi-center temple-video-sec">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="samadhi-center-text">
                        <h4 className="text-dark-red fw-700 heading fs-41 mb-20">Welcome to Saptharishi Vedic Services</h4>
                        <p className="text-custom-black mb-xl-20">Just over the years, we have thought to bring a fascinated platform of global vedic services to connect the public & Hindu gurukals to perform the Vedic rituals in a well-organized & traditional way. In this digital world we need to educate our upcoming generation about the importance and spiritual values of our culture. </p>
                        <p className="mb-xl-20 text-custom-pink">Saptharishi Vedic Services helps to connects the patron & gurukal by its establishment.</p>
                        <h3 className="text-center mb-0">What we do</h3>
                            <img src="assets/images/How-We-Work Vedic.jpg" alt="How We Work Vedic"/>
                        <div className="signature-img">
                            <img src="Logo-Horizontal-h120.png" alt="img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End samadhi-center --> */}
    {/* <!-- single work gallery start --> */}
    {/* <div className="project-list">
        <div className="container-fluid">
            <div className="row no-gutters">
                <div className="col-lg-3 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-6">
                    <div className="project-img">
                        <img src="https://via.placeholder.com/475x460" alt=""/>
                        <div className="expand transform-center">
                            <a href="https://via.placeholder.com/475x460" className=" gallery-links yellow"> <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    {/* <!-- single work gallery end --> */}
    {/* <!-- Start temple-service --> */}
    <section className="pt-80 pb-80 temple-service-sec temple-video-sec">
        <div className="overlay overlay-bg-light"></div>
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">Services</h5>
                    <h3 className="text-dark-red fw-700 fs-41">Our Latest Services</h3>
                </div>
                <div className="section-description">
                    <p className="text-custom-black"> </p>
                </div>
            </div>
            {displayCategory()}
        </div>
    </section>
    {/* <!-- End temple-service --> */}
    {/* <!-- Start Download App --> */}
    <section className="pt-80 parallax download-app">
        <div className="overlay overlay-bg-black"></div>
        <div className="container">
            <div className="row">
                <div className="col-lg-7 align-self-center">
                    <div className="app-text pt-80 pb-80">
                        <div className="section-header">
                            <div className="section-heading">
                                <h5 className="text-custom-pink">Download the App</h5>
                                <h3 className="text-custom-white fw-700 fs-41">Belive, Watch, Listen</h3>
                            </div>
                            <div className="section-description">
                                <p className="text-custom-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                            </div>
                        </div>
                        <div className="img-box">
                            <a href="#">
                                <img src="https://via.placeholder.com/181x55" className="img-fluid" alt="img"/>
                            </a>
                            <a href="#">
                                <img src="https://via.placeholder.com/181x55" className="img-fluid" alt="img"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End Download App --> */}
    {/* <!-- Blog start --> */}
    <section className="our-articles pb-80 pt-80">
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">Our Blog</h5>
                    <h3 className="text-dark-red fw-700 fs-41">Our Weekly Blog</h3>
                </div>
                <div className="section-description">
                    <p className="text-custom-black">Our recent activities and updates</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="slider-sec">
                        {displayPosts()}
                        <div className="blog-nav slider-nav">
                            <a href="#" className="blog-nav-left left-nav bg-yellow text-custom-white"> <i className='fas fa-long-arrow-alt-left'></i>
                            </a>
                            <a href="#" className="blog-nav-right right-nav  bg-yellow text-custom-white"> <i className='fas fa-long-arrow-alt-right'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Blog end --> */}
    {/* <!-- Start testimonials --> */}
    <section className="pt-80 pb-80 parallax temple-testimonials">
        <div className="overlay overlay-bg-black"></div>
        <div className="container">
            <div className="section-header">
                <div className="section-heading">
                    <h5 className="text-custom-pink">Our Testimonials</h5>
                    <h3 className="text-custom-white fw-700 fs-41">Feedback From Clients</h3>
                </div>
                <div className="section-description">
                    <p className="text-custom-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="slider-sec">
                        <OwlCarousel className="owl-carousel owl-theme"
                        loop={true}
                        margin={30}
                        nav={false}
                        dots={false}
                        autoplay={true}
                        autoplayTimeout={4000}>
                            <div className="item">
                                <div className="testimonial-block">
                                    <div className="text">
                                        <span className="fa fa-quote-left text-custom-pink"></span>
                                        <p className="text-custom-black fs-16">Quisque sollicitudin feugiat risus, eu posuere ex euismod eu. Phasellus hendrerit, massa efficitur dapibus pulvinar, sapien eros sodales ante, euismod aliquet nulla metus a mauris.</p>
                                    </div>
                                    <div className="quote">
                                    <h6 className="name"><a href="#" className="text-dark-red fs-16">Brian Wright</a></h6>
                                    <p className="designation text-custom-black">Founder</p>
                                    <img src="https://via.placeholder.com/80" className="img-fluid" alt="#"/></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-block">
                                    <div className="text">
                                        <span className="fa fa-quote-left text-custom-pink"></span>
                                        <p className="text-custom-black fs-16">Quisque sollicitudin feugiat risus, eu posuere ex euismod eu. Phasellus hendrerit, massa efficitur dapibus pulvinar, sapien eros sodales ante, euismod aliquet nulla metus a mauris.</p>
                                    </div>
                                    <div className="quote">
                                    <h6 className="name"><a href="#" className="text-dark-red fs-16">Brian Wright</a></h6>
                                    <p className="designation text-custom-black">Founder</p>
                                    <img src="https://via.placeholder.com/80" className="img-fluid" alt="#"/></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-block">
                                    <div className="text">
                                        <span className="fa fa-quote-left text-custom-pink"></span>
                                        <p className="text-custom-black fs-16">Quisque sollicitudin feugiat risus, eu posuere ex euismod eu. Phasellus hendrerit, massa efficitur dapibus pulvinar, sapien eros sodales ante, euismod aliquet nulla metus a mauris.</p>
                                    </div>
                                    <div className="quote">
                                    <h6 className="name"><a href="#" className="text-dark-red fs-16">Brian Wright</a></h6>
                                    <p className="designation text-custom-black">Founder</p>
                                    <img src="https://via.placeholder.com/80" className="img-fluid" alt="#"/></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-block">
                                    <div className="text">
                                        <span className="fa fa-quote-left text-custom-pink"></span>
                                        <p className="text-custom-black fs-16">Quisque sollicitudin feugiat risus, eu posuere ex euismod eu. Phasellus hendrerit, massa efficitur dapibus pulvinar, sapien eros sodales ante, euismod aliquet nulla metus a mauris.</p>
                                    </div>
                                    <div className="quote">
                                    <h6 className="name"><a href="#" className="text-dark-red fs-16">Brian Wright</a></h6>
                                    <p className="designation text-custom-black">Founder</p>
                                    <img src="https://via.placeholder.com/80" className="img-fluid" alt="#"/></div>
                                </div>
                            </div>
                        </OwlCarousel>
                        <div className="blog-nav slider-nav">
                            <a href="#" className="testi-nav-left left-nav bg-yellow text-custom-white"> <i className='fas fa-long-arrow-alt-left'></i>
                            </a>
                            <a href="#" className="testi-nav-right right-nav  bg-yellow text-custom-white"> <i className='fas fa-long-arrow-alt-right'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- End testimonials --> */}
    {/* <!-- cta start --> */}
    <section className="cta bg-yellow pt-30 pb-30">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-9 col-md-12 text-center text-lg-left">
                    <h3 className="f-700 fs-35 mb-md-20">Not A Member? Connect With Us.. </h3>
                </div>
                <div className="col-lg-3 col-md-12 text-center text-lg-right"> <a href="/contacts" className="btn btn-black shadow-1">CONNECT WITH US</a>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- cta end --> */}
</div>
    );
};

export default Home;