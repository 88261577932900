import React, { Component } from 'react'
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { logout } from '../../Actions/auth'
import {Link} from 'react-router-dom';

class Logout extends Component {

    static propTypes ={
        AuthReducer: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    }

    render() {

        const { isAuthenticated, user } = this.props.AuthReducer
        const authLinks = (
            <ul className="custom">
                <li>
                    <Link to="/myaccount"><i className="fas fa-user"></i> {user ? `Welcome, ${user.first_name} ${user.last_name}` : ""}</Link> 
                </li>
                <li>
                <a href="#" onClick={this.props.logout}><i className="fas fa-sign-out-alt"></i> Logout</a>
                </li>
                <li>
                    <a href="cart.html"><i className="fas fa-heart"></i> Wishlist</a>
                </li>
            </ul>
          );
      
          const guestLinks = (
            <ul className="custom">
                <li>
                <Link to="/register"><i className="fas fa-user-lock"></i> Register</Link>
                </li>
                <li>
                <Link to="/login"><i className="fas fa-sign-in-alt"></i> Login </Link>
                </li>
                <li>
                    <a href="cart.html"><i className="fas fa-heart"></i> Wishlist</a>
                </li>
            </ul>
          );
        return (
            <div className="top-link right-side">
                {isAuthenticated ? authLinks : guestLinks}
            </div>
        )
    }
}

const mSTP = (state) =>({
    AuthReducer: state.AuthReducer
})

export default connect(mSTP, { logout })(Logout)
